import React, { FC } from 'react'
import {
  DND_ITEM_ENUM,
  DnDItemProjectPagePayload,
  DnDItemTablistPagePayload,
} from '../../../models/dnd.types'
import PutasideTabView, { PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM } from './PutasideTabView'
import { TABLIST_PAGE_ENUM } from '../../../models/tablist_pages.types'

function emptyFunc(_event: React.MouseEvent<HTMLElement, MouseEvent>): void {
  throw new Error('Function not implemented.')
}

interface Props {
  item: DnDItemProjectPagePayload | DnDItemTablistPagePayload
}

const PageDragPreview: FC<Props> = (props) => {
  const { item } = props
  const page = item.page

  return (
    <PutasideTabView
      key={page.id}
      entityType={
        item.type === DND_ITEM_ENUM.PROJECT_PAGE
          ? TABLIST_PAGE_ENUM.PROJECT_PAGE
          : item.page.entity_type
      }
      title={
        page.custom_title && page.custom_title.length > 0
          ? page.custom_title
          : page.title ?? 'Untitled Page'
      }
      favIconUrl={page.favicon_url}
      isTitleClickDisabled={true}
      isFocused={false}
      isCheckboxShown={false}
      showTimeString={false}
      showActionIcons={false}
      isDraggingDisabled={true}
      showOpenTabIcon={item.type === DND_ITEM_ENUM.PROJECT_PAGE ? false : item.page.is_open}
      lastAccessDateTime={
        item.type === DND_ITEM_ENUM.PROJECT_PAGE
          ? item.page.last_access_datetime ?? ''
          : item.page.last_access_datetime_utc_iso
      }
      handleClickFavIcon={emptyFunc}
      handleClickDragIcon={emptyFunc}
      handleClickTitle={emptyFunc}
      handleClickBody={emptyFunc}
      isDraggingThis={false}
      isSelected={false}
      isTabAboveSelected={false}
      isTabBelowSelected={false}
      isSelectionDisabled={false}
      isHoverDisabled={false}
      styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.DEFAULT}
      isDragPreview={true}
      isDragIconPlaceholderHidden={false}
      shouldShowDeleteIcon={false}
    />
  )
}

export default PageDragPreview
