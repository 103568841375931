import React, { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthContext'

const RequireAuth: FC<{ children: React.JSX.Element; loginPath: string }> = ({
  children,
  loginPath,
}) => {
  const { isAuthenticated } = useAuthContext()

  if (!isAuthenticated) {
    return <Navigate to={loginPath} />
  }
  return children
}

export default React.memo(RequireAuth)
