import * as Sentry from '@sentry/react'
import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_SERVER_URL, TRACED_ENDPOINTS } from '../../constants'
import { ReduxStateType } from '../baseStore'
import {
  FetchArgs,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

const customFetchBaseQuery = (baseQueryOptions: FetchBaseQueryArgs) => {
  const baseQuery = fetchBaseQuery(baseQueryOptions)

  return async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: object) => {
    let result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
    if (TRACED_ENDPOINTS.has(api.endpoint)) {
      result = await Sentry.startNewTrace(() =>
        Sentry.startSpan(
          { name: api.endpoint },
          async () => await baseQuery(args, api, extraOptions),
        ),
      )
    } else {
      result = await baseQuery(args, api, extraOptions)
    }

    // if (result.error) {
    //   console.error('Base query error:', result.error)
    //   const payload = {
    //     endpoint: api.endpoint,
    //     error: result.error,
    //   }
    // Sentry.getCurrentScope().setTransactionName(api.endpoint)
    // Sentry.captureException(payload)
    // }

    return result
  }
}

export const skeemaApi = createApi({
  reducerPath: 'skeemaApi',
  baseQuery: customFetchBaseQuery({
    baseUrl: `${API_SERVER_URL}`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Content-Type', 'application/json')

      const state = getState() as ReduxStateType
      const userOverride = state.user.userOverride
      if (userOverride) {
        headers.set('Skipper-User-Override', userOverride)
      }

      const authHeader = state.user.authHeader

      if (authHeader) {
        headers.set('Authorization', authHeader)
      }
      return headers
    },
    timeout: 10000,
  }),
  refetchOnFocus: true,
  endpoints: () => ({}),
  tagTypes: [
    'HistoryItem',
    'ProjectPermissions',
    'SmartSessions',
    'ProjectDetailPages',
    'ActiveProjects',
  ],
})
