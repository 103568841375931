import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { isMac } from '../../../utils/utils'
import {
  SKIPPER_TAB_LOCATION_WINDOWS_IMG_URL,
  SKIPPER_TAB_LOCATION_MAC_IMG_URL,
} from '../../../constants'
import { Flex } from '@chakra-ui/react'
import { styled } from 'styled-components'

const SKIPPER_TAB_LOCATION_IMG_URL = isMac()
  ? SKIPPER_TAB_LOCATION_MAC_IMG_URL
  : SKIPPER_TAB_LOCATION_WINDOWS_IMG_URL

const cmdOrCtrl = isMac() ? 'Cmd' : 'Ctrl'

const KeyIcon = styled.span`
  position: relative;
  top: -2px;
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(78, 78, 78);
  color: white;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px 6px;
`

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingConclusionDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props

  return (
    <OnboardingStepTemplate
      phaseText={`3. OPENING TABS`}
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={
        <>
          {`Lastly, Skipper is for opening the tabs you need next.`}
          <br />
          <br />
          {`Skipper is pinned on the far left of your tab bar (`}
          <KeyIcon style={{ marginLeft: '6px' }}>{cmdOrCtrl}</KeyIcon>
          <KeyIcon>1</KeyIcon>
          {`). Open the tabs in your new folder!`}
        </>
      }
      bodyContent={
        <Flex minHeight="175px" justifyContent="center" alignItems="center">
          <img alt="Instructional image for Skipper" src={SKIPPER_TAB_LOCATION_IMG_URL} />
        </Flex>
      }
      buttonText={`Go to Skipper`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingConclusionDialog
