import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
interface Props {
  phaseNum: number
  numPhases: number
}
const OnboardingNewFolderButtonDialog: FC<Props> = (props) => {
  const { phaseNum, numPhases } = props

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      phaseText={`2. ORGANIZING`}
      numPhases={numPhases}
      heading={
        <span
          style={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: 'inherit',
            color: 'inherit',
            letterSpacing: 'inherit',
          }}
        >
          The second thing Skipper helps with is organizing related tabs together into folders.
          <br />
          <br />
          Think bookmarks, but smarter. Click the{' '}
          <span
            style={{
              whiteSpace: 'nowrap',
              fontSize: 'inherit',
              fontWeight: 'inherit',
              lineHeight: 'inherit',
              color: 'inherit',
              letterSpacing: 'inherit',
            }}
          >
            [+ New Folder]
          </span>{' '}
          button to set up your first one now.
        </span>
      }
    />
  )
}
export default OnboardingNewFolderButtonDialog
