import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import Portal from '../../../widgets/Portal'

interface Position {
  top: number
  left: number
  width: number
  height: number
}

interface Props {
  children: React.ReactNode
  anchorRef: React.RefObject<HTMLDivElement>
  portalContainerRef?: React.RefObject<HTMLDivElement>
  forceUpdate?: unknown
  isDisabled?: boolean
  xOffset?: number
  yOffset?: number
  widthOffset?: number
  heightOffset?: number
  shouldDisablePointerEvents?: boolean
}

const SpotlightWrapper: FC<Props> = (props) => {
  const {
    children,
    anchorRef,
    portalContainerRef,
    forceUpdate,
    isDisabled = false,
    xOffset = 0,
    yOffset = 0,
    widthOffset = 0,
    heightOffset = 0,
    shouldDisablePointerEvents = false,
  } = props
  const [position, setPosition] = useState<Position>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  })

  const updatePosition = useCallback(() => {
    const element = anchorRef.current
    if (!element) {
      return
    }

    const rect = element.getBoundingClientRect()
    setPosition({
      top: rect.top + yOffset,
      left: rect.left + xOffset,
      width: rect.width + widthOffset,
      height: rect.height + heightOffset,
    })
  }, [anchorRef, xOffset, yOffset, widthOffset, heightOffset])

  useEffect(() => {
    updatePosition()

    // Sometimes the position doesn't update immediately, so we need to wait for a bit
    const timeouts = [25, 50, 100, 150, 200, 400, 800].map((delay) => {
      return setTimeout(() => {
        updatePosition()
      }, delay)
    })

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
  }, [forceUpdate, updatePosition])

  useEffect(() => {
    updatePosition()

    // Update position on resize and scroll
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition)

    return () => {
      window.removeEventListener('resize', updatePosition)
      window.removeEventListener('scroll', updatePosition)
    }
  }, [anchorRef, portalContainerRef, updatePosition])

  if (isDisabled) {
    return <>{children}</>
  }

  return (
    <Portal>
      <Box
        position="fixed"
        left={0}
        top={0}
        width="100vw"
        height="100vh"
        bg="rgba(0, 0, 0, 0.48)"
        backdropFilter="auto"
        backdropBlur={'3px'}
        zIndex={3}
      />
      <Box
        ref={portalContainerRef}
        position="fixed"
        top={`${position.top}px`}
        left={`${position.left}px`}
        width={`${position.width}px`}
        height={`${position.height}px`}
        transition="all 0.3s ease-in-out"
        pointerEvents={shouldDisablePointerEvents ? 'none' : 'auto'}
        zIndex={4}
      >
        {children}
      </Box>
    </Portal>
  )
}

export default SpotlightWrapper
