import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Input,
  Button,
  Box,
  Wrap,
  WrapItem,
  Text,
  Icon,
  Flex,
  ButtonGroup,
  Tooltip,
  Spinner,
  Center,
} from '@chakra-ui/react'
import {
  useCreateProjectMutation,
  useGetActiveProjectsQuery,
  useLazyGetProjectRecommendationsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { HiSparkles } from 'react-icons/hi'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import {
  addPendingFolderCreationPages,
  cancelFolderCreationMode,
  selectPendingFolderCreationPages,
  selectPendingFolderCreationTitle,
  selectSuggestedFolderCreationPages,
  selectSuggestedFolderCreationTitles,
  setPendingFolderCreationTitle,
  setSuggestedFolderCreationPages,
  setSuggestedFolderCreationTitles,
} from '../../../redux/projectsSlice'
import { MdHelpOutline } from 'react-icons/md'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useUserContext } from '../../../contexts/UserContext'
import { ProjectType } from '../../../models/saved_sessions.types'
import { useNavigate } from 'react-router-dom'
import { DnDItemPayload } from '../../../models/dnd.types'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM } from '../../../models/dnd.types'
import { SIDEBAR_TRANSITION_DURATION_MS } from '../MainDashboard'
import { PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM } from '../PutasideTabList/PutasideTabView'
import { clearSelectedPages } from '../../../redux/selectedPagesSlice'

const ProjectCreationWidget: FC = () => {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { captureAnalytics } = useUserContext()
  const pendingTitle = useReduxSelector(selectPendingFolderCreationTitle)
  const suggestedTitles = useReduxSelector(selectSuggestedFolderCreationTitles)
  const suggestedPages = useReduxSelector(selectSuggestedFolderCreationPages)

  const [isDebounceLoading, setIsDebounceLoading] = useState<boolean>(false)
  const [debouncedFolderName, setDebouncedFolderName] = useState<string>('')

  const dropTargetContainerRef = useRef<HTMLDivElement | null>(null)
  const nextNameChangeUpdateRef = useRef<NodeJS.Timeout | null>(null)
  const pendingPages = useReduxSelector(selectPendingFolderCreationPages)
  const numPendingPages = pendingPages.length

  const [isSidebarFullyExpanded, setIsSidebarFullyExpanded] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setIsSidebarFullyExpanded(true)
    }, SIDEBAR_TRANSITION_DURATION_MS)
  }, [])

  const [
    getProjectRecommendations,
    {
      data: recommendationsQueryData,
      isFetching: isFetchingRecommendationsQuery,
      isError: isErrorRecommendationsQuery,
    },
  ] = useLazyGetProjectRecommendationsQuery({
    refetchOnFocus: false,
  })

  const isLoadingRecommendations = isDebounceLoading || isFetchingRecommendationsQuery

  useEffect(() => {
    getProjectRecommendations(
      {
        title: debouncedFolderName,
        pages: pendingPages,
      },
      true,
    )
  }, [debouncedFolderName, pendingPages, getProjectRecommendations])

  useEffect(() => {
    if (!recommendationsQueryData) {
      return
    }

    dispatch(setSuggestedFolderCreationTitles(recommendationsQueryData.titles ?? []))
    dispatch(setSuggestedFolderCreationPages(recommendationsQueryData.pages))
  }, [dispatch, recommendationsQueryData])

  const handleFolderNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPendingFolderCreationTitle(e.target.value))

      if (nextNameChangeUpdateRef.current) {
        clearTimeout(nextNameChangeUpdateRef.current)
      }

      setIsDebounceLoading(true)
      nextNameChangeUpdateRef.current = setTimeout(() => {
        setDebouncedFolderName(e.target.value)
        setIsDebounceLoading(false)
      }, 1000)
    },
    [dispatch],
  )

  const handleClickTitleSuggestion = useCallback(
    (title: string) => {
      dispatch(setPendingFolderCreationTitle(title))
      setDebouncedFolderName(title)

      captureAnalytics(`project_creation_widget:title_suggestion_click`, {
        title,
        suggestedTitles,
      })
    },
    [captureAnalytics, dispatch, suggestedTitles],
  )

  const [createProjectMutation] = useCreateProjectMutation()

  const { data: projects } = useGetActiveProjectsQuery(undefined)
  const handleCreateProject = useCallback(
    async (params: {
      tablistPages: TablistPageType[]
      title?: string
      order?: number
    }): Promise<ProjectType | undefined> => {
      const { tablistPages, title, order } = params

      const result = await createProjectMutation({
        tablistPages,
        title,
        existingProjects: projects,
        order,
        titlePrefix: 'Folder',
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      return result
    },
    [createProjectMutation, projects],
  )

  const handleClickAddAllSuggestedTabs = useCallback(() => {
    if (!suggestedPages) {
      return
    }

    dispatch(addPendingFolderCreationPages({ pages: suggestedPages, index: 0 }))

    captureAnalytics(`project_creation_widget:add_all_suggested_tabs_click`, {
      numPages: suggestedPages.length,
    })
  }, [captureAnalytics, dispatch, suggestedPages])

  const handleClickSave = useCallback(async () => {
    const project = await handleCreateProject({
      tablistPages: pendingPages,
      title: pendingTitle,
    })

    captureAnalytics(`project_creation_widget:save_click`, {
      numPages: pendingPages.length,
      title: pendingTitle,
      pages: pendingPages,
      projectId: project?.id,
    })

    if (project?.id) {
      navigate(`/folders/${project?.id}`)
    }

    dispatch(cancelFolderCreationMode())
  }, [handleCreateProject, pendingPages, pendingTitle, captureAnalytics, dispatch, navigate])

  const handleClickCancel = useCallback(() => {
    captureAnalytics(`project_creation_widget:cancel_click`, {
      numPages: pendingPages.length,
      title: pendingTitle,
      pages: pendingPages,
    })
    dispatch(cancelFolderCreationMode())
  }, [pendingPages, pendingTitle, captureAnalytics, dispatch])

  const { projectConfig } = useFeatureFlagContext()
  const maxNumPages = projectConfig.maxNumProjectsPages
  const isProjectPageLimitReached = numPendingPages >= maxNumPages
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const [dragOverLocation, setDragOverLocation] = useState<'top' | 'bottom' | undefined>(undefined)
  const dragOverLocationRef = useRef<'top' | 'bottom' | undefined>(undefined)

  useEffect(() => {
    dragOverLocationRef.current = dragOverLocation
  }, [dragOverLocation])

  const [{ isDraggingOverThis, shouldShowPageLimitError }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => {
        const pl: DnDItemPayload | null = monitor.getItem()
        const payload =
          pl?.type === DND_ITEM_ENUM.TABLIST_PAGES && pl?.payloads?.length === 1
            ? pl.payloads[0]
            : pl

        const isDraggingOverThis = monitor.isOver()

        const isDragItemFromThisProject =
          payload?.type === DND_ITEM_ENUM.TABLIST_PAGE &&
          payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION
        const numAddPages = isDragItemFromThisProject
          ? 0
          : payload?.type === DND_ITEM_ENUM.SMART_SESSION
            ? payload?.session.pages.length
            : payload?.type === DND_ITEM_ENUM.TABLIST_PAGES
              ? payload?.payloads.length
              : 1
        const newNumPages = numPendingPages + numAddPages

        const shouldShowPageLimitError = isDraggingOverThis && newNumPages > maxNumPages

        return {
          isDraggingOverThis,
          shouldShowPageLimitError,
        }
      },
      canDrop: (pl: DnDItemPayload) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        if (payload.type === DND_ITEM_ENUM.TABLIST_PAGE) {
          if (isProjectPageLimitReached) {
            return false
          }

          const isFromThisProject =
            payload?.type === DND_ITEM_ENUM.TABLIST_PAGE &&
            payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION

          if (isFromThisProject) {
            const isTopPointless = payload.index === 0 && dragOverLocationRef.current === 'top'
            const isBottomPointless =
              payload.index === numPendingPages - 1 && dragOverLocationRef.current === 'bottom'
            if (isTopPointless || isBottomPointless) {
              return false
            }
          }
        } else if (
          payload.type === DND_ITEM_ENUM.SMART_SESSION ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const newNumPages =
            numPendingPages +
            (payload.type === DND_ITEM_ENUM.SMART_SESSION
              ? payload.session.pages.length
              : payload.payloads.length)
          if (newNumPages > maxNumPages) {
            return false
          }
        }

        return true
      },
      hover: (pl, monitor) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        const { y } = monitor.getClientOffset() ?? {}
        const { top, bottom, height } =
          dropTargetContainerRef.current?.getBoundingClientRect() ?? {}
        if (top === undefined || bottom === undefined || y === undefined || height === undefined) {
          setDragOverLocation(undefined)
          return
        }

        if (monitor.isOver({ shallow: true })) {
          //Dropping a page on the project container edges/title
          const newDragLocation = y < top + 60 ? 'top' : y > bottom - 18 ? 'bottom' : undefined

          const isFromThisProject =
            payload?.type === DND_ITEM_ENUM.TABLIST_PAGE &&
            payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION
          if (isFromThisProject) {
            const isTopPointless = payload.index === 0 && newDragLocation === 'top'
            const isBottomPointless =
              payload.index === numPendingPages - 1 && newDragLocation === 'bottom'
            if (isTopPointless || isBottomPointless) {
              //Trying to drop in the same location
              setDragOverLocation(undefined)
              return
            }
          }

          setDragOverLocation(newDragLocation)
        } else {
          setDragOverLocation(undefined)
        }
      },
      drop: (pl: DnDItemPayload, monitor) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        if (monitor.didDrop()) {
          //Another drop target (a child item) received the drop event already
          return { status: 'DID_DROP' }
        }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const index = dragOverLocationRef.current === 'bottom' ? numPendingPages : 0

          const payloads =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.payloads : [payload]
          const tablistPages = payloads.map((pl) => pl.page)
          dispatch(addPendingFolderCreationPages({ pages: tablistPages, index }))
          dispatch(clearSelectedPages())

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const index = dragOverLocationRef.current === 'bottom' ? numPendingPages : 0
          const tablistPages = [...payload.session.pages]
          if (pendingTitle === '') {
            dispatch(setPendingFolderCreationTitle(payload.session.name))
          }
          dispatch(addPendingFolderCreationPages({ pages: tablistPages, index }))
          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [dispatch, pendingTitle, numPendingPages, maxNumPages, isProjectPageLimitReached],
  )

  const connectDnd = useCallback(
    (node: HTMLDivElement | null) => {
      dropTargetContainerRef.current = node
      connectDropTarget(dropTargetContainerRef)
    },
    [connectDropTarget],
  )

  return (
    <Flex
      direction="column"
      w="100%"
      overflow="hidden"
      p="24px"
      borderRadius="16px"
      bg="#FFF"
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.12)"
    >
      <Text
        as="h2"
        ml="6px"
        mb="40px"
        color="#000"
        fontSize="16px"
        fontWeight="600"
        lineHeight="24px"
      >
        New Folder
      </Text>

      <Text ml="6px" mb="10px" color="#A7A7A7" fontSize="14px" fontWeight="600" lineHeight="22px">
        Name
      </Text>

      <Input
        mb={4}
        h="36px"
        p="4px 8px"
        borderRadius="6px"
        border="1px solid #D5D5D5"
        background="#FFF"
        placeholder="Enter folder name"
        value={pendingTitle}
        onChange={handleFolderNameChange}
        color="#585858"
        fontSize="16px"
        fontWeight="600"
        lineHeight="24px"
        _placeholder={{
          color: '#A7A7A7',
        }}
        _focus={{
          borderRadius: '6px',
          border: '1px solid #0071E3',
          background: '#FFF',
          boxShadow: '0px 0px 0px 4px rgba(0, 113, 227, 0.25)',
        }}
      />

      {!pendingTitle && pendingPages.length === 0 && !isErrorRecommendationsQuery && (
        <Box mb="24px">
          <Flex ml="6px" mb="10px" w="100%" alignItems="center" position="relative">
            <Icon as={HiSparkles} mr="6px" color="#0071e3" />
            <Text color="#0071e3" fontSize="14px" fontWeight="600" lineHeight="22px">
              Recommended folders for you
            </Text>
            <Tooltip
              label={`Based on your recent browsing, Skipper AI recommends setting up the folders below`}
              placement="top"
            >
              <Flex ml="8px" alignItems="center" justifyContent="center">
                <Icon as={MdHelpOutline} color="#0071e3" />
              </Flex>
            </Tooltip>
          </Flex>

          <Wrap
            spacing="8px"
            maxHeight={isSidebarFullyExpanded ? '300px' : '0px'}
            opacity={isSidebarFullyExpanded ? 1 : 0}
            transition="all 0.6s ease-in-out"
            overflow="hidden"
          >
            {!isLoadingRecommendations &&
              isSidebarFullyExpanded &&
              suggestedTitles?.slice(0, 3).map((title, index) => (
                <WrapItem key={index}>
                  <Button
                    size="sm"
                    h="28px"
                    p="0 12px"
                    border="1px solid #0071E3"
                    background="#CCE3F9"
                    color="#0071E3"
                    borderRadius="8px"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="16px"
                    onClick={() => handleClickTitleSuggestion(title)}
                  >
                    {title}
                  </Button>
                </WrapItem>
              ))}
          </Wrap>

          {(isLoadingRecommendations || !isSidebarFullyExpanded || !suggestedTitles) && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Spinner color="blue.500" size="md" speed="1s" />
            </Flex>
          )}

          {!isLoadingRecommendations && suggestedTitles && suggestedTitles.length === 0 && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Text textAlign={'center'} fontSize="12px" color="#a7a7a7">
                {`No recommendations found`}
              </Text>
            </Flex>
          )}
        </Box>
      )}

      <Box ref={connectDnd}>
        <Flex mb="10px" justifyContent="space-between" alignItems="flex-end">
          <Text ml="6px" color="#A7A7A7" fontSize="14px" fontWeight="600" lineHeight="22px">
            Tabs
          </Text>
          {pendingPages.length > 0 && (
            <Text fontSize="12px" fontWeight="500" color="#a7a7a7" mr="87px">
              {`Last used`}
            </Text>
          )}
        </Flex>

        {pendingPages.length === 0 && (
          <Box
            border={isTablistPageDragging ? '2px dashed #0071E3' : '2px dashed #D5D5D5'}
            bg={
              isTablistPageDragging
                ? isDraggingOverThis
                  ? 'rgba(0, 113, 227, 0.40)'
                  : 'rgba(0, 113, 227, 0.20)'
                : '#F6F6F6'
            }
            color={isTablistPageDragging ? '#0071E3' : '#585858'}
            borderRadius="6px"
            p={4}
            mb={4}
            textAlign="center"
            fontSize="14px"
            fontWeight="500"
          >
            {`Drag and drop tabs here or click "+ Add" next to any tab`}
          </Box>
        )}

        {pendingPages.length > 0 && (
          <Box
            position="relative"
            mb={4}
            border={
              shouldShowPageLimitError
                ? '2px dashed #F03F36'
                : isTablistPageDragging
                  ? '2px dashed #0071E3'
                  : '2px dashed transparent'
            }
            bg={
              shouldShowPageLimitError
                ? 'rgba(240, 63, 54, 0.20)'
                : isTablistPageDragging
                  ? isDraggingOverThis
                    ? 'rgba(0, 113, 227, 0.40)'
                    : 'rgba(0, 113, 227, 0.20)'
                  : 'transparent'
            }
            transition="all 0.2s ease-in-out"
            borderRadius="6px"
          >
            {shouldShowPageLimitError && (
              <Flex
                alignItems="center"
                justifyContent="center"
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                w="100%"
                h="100%"
                zIndex={20}
              >
                <Center
                  margin="8px"
                  padding="8px 16px"
                  background="white"
                  borderRadius="8px"
                  width="fit-content"
                >
                  <Text color="#ec5f58" fontSize="14px" fontWeight="600" textAlign="center">
                    {`Oops!`}
                    <br />
                    {`Projects are limited to ${maxNumPages} tabs`}
                  </Text>
                </Center>
              </Flex>
            )}
            {pendingPages.map((tab, index) => (
              <PutasideTabController
                key={tab.id}
                areaName={TABLIST_AREA_NAME_ENUM.FolderCreation}
                id={tab.id}
                page={tab}
                queryValue={''}
                index={index}
                numTotalResults={pendingPages.length}
                styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.FOLDER_CREATION}
                isHighlighted={false}
                isSelected={false}
                isTabAboveSelected={false}
                isTabBelowSelected={false}
                shouldShowDeleteIcon={true}
                isPendingInFolderCreation={true}
                shouldForceHighlightTop={
                  index === 0 && isDraggingOverThis && dragOverLocation === 'top'
                }
                shouldForceHighlightBottom={
                  index === pendingPages.length - 1 &&
                  isDraggingOverThis &&
                  dragOverLocation === 'bottom'
                }
                isProjectPageLimitReached={isProjectPageLimitReached}
                numProjectPages={pendingPages.length}
                maxNumProjectPages={maxNumPages}
                isTitleClickDisabled={true}
              />
            ))}
          </Box>
        )}
      </Box>

      {(!!pendingTitle ||
        pendingPages.length > 0 ||
        (suggestedPages && suggestedPages.length > 0)) &&
        !isErrorRecommendationsQuery && (
          <Box mb="24px">
            <Flex ml="6px" mb="10px" w="100%" alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <Icon as={HiSparkles} mr="6px" color="#0071e3" />
                <Text color="#0071e3" fontSize="14px" fontWeight="600" lineHeight="22px">
                  Recommended tabs
                </Text>
                <Tooltip
                  label={`Based on the folder you're creating, Skipper AI recommends including the tabs below`}
                  placement="top"
                >
                  <Flex ml="8px" alignItems="center" justifyContent="center">
                    <Icon as={MdHelpOutline} color="#0071e3" />
                  </Flex>
                </Tooltip>
              </Flex>
              {suggestedPages && suggestedPages.length > 1 && (
                <Tooltip placement="top" label={'Add all tabs to folder'}>
                  <Flex
                    bg="#CCE3F9"
                    borderRadius="6px"
                    color="#0071E3"
                    w="67px"
                    h="22px"
                    flexShrink={0}
                    alignItems="center"
                    justifyContent="center"
                    onClick={handleClickAddAllSuggestedTabs}
                    cursor="pointer"
                    mr="10px"
                  >
                    <Text fontSize="12px" fontWeight="500">
                      + Add all
                    </Text>
                  </Flex>
                </Tooltip>
              )}
            </Flex>

            {!isLoadingRecommendations && (
              <Box
                border="2px dashed transparent"
                // The border keeps these tabs aligned with the tabs in the dnd zone above
              >
                {suggestedPages?.map((tab, index) => (
                  <PutasideTabController
                    key={tab.id}
                    id={tab.id}
                    page={tab}
                    queryValue={''}
                    index={index}
                    numTotalResults={suggestedPages.length}
                    areaName={TABLIST_AREA_NAME_ENUM.FolderCreation}
                    styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.FOLDER_CREATION}
                    onOpenMoveMenu={() => {}}
                    isHighlighted={false}
                    isSelected={false}
                    isTabAboveSelected={false}
                    isTabBelowSelected={false}
                    onSelected={() => {}}
                    removeSelection={() => {}}
                    shouldShowFolderCreationAddIcon={true}
                    isTitleClickDisabled={true}
                  />
                ))}
              </Box>
            )}

            {(isLoadingRecommendations || !suggestedPages) && (
              <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
                <Spinner color="blue.500" size="md" speed="1s" />
              </Flex>
            )}

            {!isLoadingRecommendations && suggestedPages && suggestedPages.length === 0 && (
              <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
                <Text textAlign={'center'} fontSize="12px" color="#a7a7a7">
                  {`No recommendations found`}
                </Text>
              </Flex>
            )}
          </Box>
        )}

      <Flex w="100%" mt="32px" justifyContent="flex-end">
        <ButtonGroup>
          <Button
            variant="outline"
            h="48px"
            p="0 32px"
            border="1px solid #585858"
            borderRadius="100px"
            fontSize="16px"
            fontWeight={500}
            color="black"
            bg="white"
            _hover={{ bg: '#EBEBEB' }}
            onClick={handleClickCancel}
          >
            Cancel
          </Button>

          <Button
            variant="solid"
            h="48px"
            p="0 32px"
            borderRadius="100px"
            fontSize="16px"
            fontWeight={500}
            color="white"
            bg="black"
            _hover={{ bg: '#585858' }}
            onClick={handleClickSave}
          >
            Save
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  )
}

export default ProjectCreationWidget
