import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TablistPageSearchResultType, TablistPageType } from '../models/tablist_pages.types'
import { ReduxStateType } from './baseStore'
import { getAppName } from '../utils/urlUtils'

export interface AppFilterOptionType {
  title: string
  count: number
  favIconUrl?: string
  favIconUrlCounter: Record<string, number>
  maxFavIconCount: number
}

export interface SearchFiltersType {
  isToRead: boolean
  isManual: boolean
  app: AppFilterOptionType | null
}

export interface SearchSliceStateType {
  focusedIdx: number | null
  numResults: number | null
  query: string
  filters: SearchFiltersType
  backendSearchResults: TablistPageSearchResultType[] | undefined
}

const initialState: SearchSliceStateType = {
  focusedIdx: null,
  numResults: null,
  query: '',
  filters: {
    isToRead: false,
    isManual: false,
    app: null,
  },
  backendSearchResults: undefined,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    moveSearchFocusedIdxDown(state) {
      if (state.numResults === null) {
        state.focusedIdx = null
        return
      }

      if (state.focusedIdx === null) {
        state.focusedIdx = 0
      } else if (state.focusedIdx < state.numResults - 1) {
        state.focusedIdx += 1
      }
    },
    moveSearchFocusedIdxUp(state) {
      if (state.numResults === null) {
        state.focusedIdx = null
        return
      }

      if (state.focusedIdx === null) {
        state.focusedIdx = 0
      } else if (state.focusedIdx > 0) {
        state.focusedIdx -= 1
      }
    },
    updateFocusedPage(
      state,
      action: {
        payload: TablistPageType | null
      },
    ) {
      if (action.payload === null) {
        state.focusedIdx = null
      }
    },
    removeBackendSearchResult(state, action: { payload: string }) {
      state.backendSearchResults = state.backendSearchResults?.filter(
        (page) => page.id !== action.payload,
      )
    },
    updateBackendSearchResults: (
      state,
      action: {
        payload: TablistPageSearchResultType[]
      },
    ) => {
      state.backendSearchResults = action.payload.map((page) => {
        return {
          ...page,
          app_name: getAppName(page.url),
        }
      })
    },
    loadMoreBackendSearchResults: (
      state,
      action: {
        payload: TablistPageSearchResultType[]
      },
    ) => {
      const newResults = action.payload.map((page) => {
        return {
          ...page,
          app_name: getAppName(page.url),
        }
      })
      state.backendSearchResults = state.backendSearchResults?.concat(newResults) ?? newResults
    },
    clearSearchResultsMetadata(state) {
      state.focusedIdx = null
      state.numResults = null
    },
    updateNumSearchResults(
      state,
      action: {
        payload: number
      },
    ) {
      state.numResults = action.payload
      state.focusedIdx = 0
    },
    updateSearchQuery(
      state,
      action: {
        payload: string
      },
    ) {
      const val = action.payload
      const newValue = state.query === '' ? val.trim() : val
      state.query = newValue
      state.backendSearchResults = undefined
    },
    clearSearchQuery(state) {
      state.query = ''
    },
    toggleToReadFilter(state) {
      state.filters.isToRead = !state.filters.isToRead
    },
    toggleIsManualFilter(state) {
      state.filters.isManual = !state.filters.isManual
    },
    updateAppFilterSelection(state, action: { payload: AppFilterOptionType | null }) {
      state.filters.app = action.payload
    },
  },
})

export const selectBackendSearchResults = (state: ReduxStateType) =>
  state.search.backendSearchResults
export const selectSearchQueryValue = (state: ReduxStateType) => state.search.query
export const selectSearchFocusedIdx = (state: ReduxStateType) => state.search.focusedIdx
export const selectSearchFilters = (state: ReduxStateType) => state.search.filters
export const selectIsSearchFilteringActive = createSelector(
  selectSearchFilters,
  (filters) => filters.isToRead || filters.isManual || filters.app !== null,
)

export const {
  moveSearchFocusedIdxUp,
  moveSearchFocusedIdxDown,
  updateFocusedPage,
  clearSearchResultsMetadata,
  updateBackendSearchResults,
  removeBackendSearchResult,
  loadMoreBackendSearchResults,
  updateNumSearchResults,
  updateSearchQuery,
  clearSearchQuery,
  toggleToReadFilter,
  toggleIsManualFilter,
  updateAppFilterSelection,
} = searchSlice.actions

export default searchSlice.reducer
