import React, { FC, useMemo } from 'react'
import { styled } from 'styled-components'
import { useGetOpenTabsQuery, useGetTabGroupsQuery } from '../../../webapp/redux/extension'
import { Text, Box, Spinner } from '@chakra-ui/react'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { TablistPageType } from '../../../models/tablist_pages.types'
import PutasideTabGroup from '../PutasideTabList/PutasideTabGroup'

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  padding-right: 94px;
  border-bottom: 1px solid #d5d5d5;
  margin-left: 16px;
  margin-right: 76px;
`

const TableHeaderCell = styled.div`
  h2 {
    font-size: 12px;
    font-weight: 500;
    color: #a7a7a7;
  }
`

const OpenTablist: FC = () => {
  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })
  const { data: openTabGroups } = useGetTabGroupsQuery(undefined, { pollingInterval: 1000 })

  const groupedTabs = useMemo(() => {
    if (!openTabPages) {
      return openTabPages
    }

    const groups: TablistPageType[][] = []
    const singles: TablistPageType[] = []

    openTabPages.reduce(
      (acc, tab) => {
        const groupId = tab.group_id ?? -1
        if (groupId === -1 || openTabGroups?.find((g) => g.id === groupId) === undefined) {
          singles.push(tab)
          return acc
        }
        if (!acc[groupId]) {
          acc[groupId] = []
          groups.push(acc[groupId])
        }

        acc[groupId].push(tab)
        return acc
      },
      {} as Record<number, TablistPageType[]>,
    )

    return [...groups, ...singles]
  }, [openTabGroups, openTabPages])

  return (
    <Box
      borderRadius="16px 16px 0px 0px"
      backgroundColor="#f6f6f6"
      width="100%"
      height="100%"
      mb="400px" // Allows scroll to prevent the onboarding dialog from blocking the tablist
      overflow="auto"
      className="scrollbars"
      display="flex"
      flexDirection="column"
    >
      <Box p="24px 8px 24px 16px">
        {!groupedTabs && (
          <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
            <Spinner color="blue.500" size="lg" speed="1s" />
          </div>
        )}

        {groupedTabs && groupedTabs.length === 0 && (
          <Box className="flex-center" mt="6px" w="100%">
            <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
              {`Wow! Your browser is already clean. We'll help you keep it that way.`}
            </Text>
          </Box>
        )}
        {groupedTabs && groupedTabs.length > 0 && (
          <SectionContainer>
            {groupedTabs && groupedTabs.length > 0 && (
              <>
                <TableHeaderContainer>
                  <TableHeaderCell>
                    <h2>Tab title</h2>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <h2>Last used</h2>
                  </TableHeaderCell>
                </TableHeaderContainer>
                <div>
                  {groupedTabs.map((t, idx) => {
                    if (Array.isArray(t)) {
                      const tabGroup = openTabGroups?.find((g) => g.id === t[0].group_id)
                      return (
                        <PutasideTabGroup
                          key={t[0].group_id}
                          title={tabGroup?.title || 'Untitled Tab Group'}
                          color={tabGroup?.color ?? 'grey'}
                          pages={t}
                        />
                      )
                    } else {
                      return (
                        <PutasideTabController
                          key={t.id}
                          id={t.id}
                          page={t}
                          showTimeString={true}
                          queryValue={''}
                          index={idx}
                          numTotalResults={(openTabPages ?? []).length}
                          areaName={TABLIST_AREA_NAME_ENUM.Open}
                          isTitleClickDisabled={true}
                          isDraggingDisabled={true}
                          isHoverDisabled={true}
                        />
                      )
                    }
                  })}
                </div>
              </>
            )}
          </SectionContainer>
        )}
      </Box>
    </Box>
  )
}

export default OpenTablist
