import React, { FC, useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { css, styled } from 'styled-components'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemPayload } from '../../../models/dnd.types'
import { cx, css as emotionCss } from '@emotion/css'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { clearSelectedPages } from '../../../redux/selectedPagesSlice'
import { Text, Icon, Flex, Box } from '@chakra-ui/react'
import { GLOBAL_WINDOW_MESSAGE_ENUM, sendGlobalWindowMessage } from '../../../utils/utils'

const blinkingAnimationClass = emotionCss`
  animation: blink 1.5s infinite;
  transition: background 0.2s, box-shadow 0.2s;

  @keyframes blink {
    0% {
      background: transparent;
      box-shadow: none;
    }
    30% {
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    }
    100% {
      background: transparent;
      box-shadow: none;
    }
  }
`

const Container = styled(Flex)<{
  $isDraggingOver: boolean
  $isHoverExpandDisabled: boolean
}>`
  &:hover,
  &.force-hover {
    background-color: #585858;

    ${({ $isDraggingOver }) =>
      $isDraggingOver
        ? css`
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%230071e3' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
            background-color: rgba(46, 69, 92);
          `
        : ''}

    ${({ $isHoverExpandDisabled }) =>
      $isHoverExpandDisabled
        ? ''
        : css`
            .info-label {
              max-height: 50px;
            }
          `}
  }
`
interface Props {
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
  handleActivateFolderCreationMode: (params: {
    tablistPages: TablistPageType[]
    title?: string
    loggingProps?: Record<string, unknown>
  }) => void
  isProjectLimitReached: boolean
  isDisabled?: boolean
  isOnboardingTutorialEnabled?: boolean
  isProjectButtonOnboardingActive?: boolean
  isOverlayOnboardingItem?: boolean
  handleProjectLimitError: () => void
}

const CreateProjectItem: FC<Props> = (props) => {
  const {
    containerRef,
    handleActivateFolderCreationMode,
    isProjectLimitReached,
    handleProjectLimitError,
    isDisabled = false,
    isOnboardingTutorialEnabled = false,
    isProjectButtonOnboardingActive = false,
    isOverlayOnboardingItem = false,
  } = props
  const dispatch = useReduxDispatch()
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const isSmartSessionDragging = useReduxSelector((state) => state.dnd.isSmartSessionDragging)
  const shouldForceHoverState = isTablistPageDragging || isSmartSessionDragging
  const [isActiveState, setIsActiveState] = useState(false)

  const [shouldBlinkSelf, setShouldBlinkSelf] = useState<boolean>(false)
  const shouldEmphasizeSelf = isOverlayOnboardingItem && isProjectButtonOnboardingActive
  const shouldHideSelf = !isOverlayOnboardingItem && isProjectButtonOnboardingActive
  const backgroundColor =
    !isOnboardingTutorialEnabled || isProjectButtonOnboardingActive ? '#000' : '#D5D5D5'

  useEffect(() => {
    if (!shouldEmphasizeSelf) {
      return
    }

    let timeout: ReturnType<typeof setTimeout> | null = null
    const handleMouseDown = () => {
      setShouldBlinkSelf(true)
      timeout = setTimeout(() => {
        setShouldBlinkSelf(false)
      }, 5000)
    }

    window.addEventListener('mousedown', handleMouseDown)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [shouldEmphasizeSelf])

  useEffect(() => {
    if (isActiveState) {
      setTimeout(() => {
        setIsActiveState(false)
      }, 500)
    }
  }, [isActiveState])

  const handleClickContainer = () => {
    if (isProjectLimitReached) {
      handleProjectLimitError()
    } else {
      if (isProjectButtonOnboardingActive) {
        sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.TRIGGER_NEXT_ONBOARDING_STEP)
      }
      handleActivateFolderCreationMode({ tablistPages: [] })
    }
  }

  const [{ isDraggingOver }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => ({
        isDraggingOver: monitor.isOver(),
      }),
      canDrop: (_payload: DnDItemPayload) => {
        return true
      },
      drop: (payload: DnDItemPayload) => {
        if (isProjectLimitReached) {
          handleProjectLimitError()
          return { status: 'ERROR: Project limit reached' }
        }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const payloads =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.payloads : [payload]
          const tablistPages = payloads.map((pl) => pl.page)
          const title = payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.groupName : undefined
          handleActivateFolderCreationMode({
            tablistPages,
            title,
          })
          dispatch(clearSelectedPages())

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const tablistPages = [...payload.session.pages]
          const title = payload.session.name

          handleActivateFolderCreationMode({
            tablistPages,
            title,
            loggingProps: {
              session: payload.session,
            },
          })
          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [handleActivateFolderCreationMode, handleProjectLimitError, isProjectLimitReached, dispatch],
  )

  const item = (
    <Container
      $isDraggingOver={isDraggingOver}
      $isHoverExpandDisabled={isProjectButtonOnboardingActive}
      className={cx({ 'force-hover': shouldForceHoverState })}
      ref={(node) => {
        if (containerRef) {
          containerRef.current = node
        }
        connectDropTarget(node)
      }}
      width={isOverlayOnboardingItem ? '100%' : 'calc(100% - 12px)'}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={backgroundColor}
      borderRadius="14px"
      padding="16px 24px"
      margin={isOverlayOnboardingItem ? '0px' : '2px 6px 16px 6px'}
      cursor="pointer"
      transition="border-color 0.2s, background-color 0.2s"
      pointerEvents={isDisabled ? 'none' : 'auto'}
      opacity={shouldHideSelf ? 0 : isDisabled ? 0.4 : 1}
      onClick={handleClickContainer}
    >
      <Flex width="100%" alignItems="center">
        <Icon
          as={MdAdd}
          minHeight="18px"
          minWidth="18px"
          height="24px"
          width="24px"
          marginRight="8px"
          color="#fff"
        />
        <Text
          as="h2"
          color="#fff"
          fontSize="14px"
          fontWeight="600"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          lineHeight="22px"
        >
          New folder
        </Text>
      </Flex>
      <Flex
        className="info-label"
        width="100%"
        alignItems="center"
        overflow="hidden"
        maxHeight="0px"
        transition="max-height 0.3s"
      >
        <Text
          as="p"
          marginLeft="34px"
          marginTop="8px"
          fontSize="12px"
          fontWeight="500"
          lineHeight="16px"
          overflow="hidden"
          color="#e0e0e0"
        >
          {`Drop a tab here or into a folder`}
        </Text>
      </Flex>
    </Container>
  )

  return shouldEmphasizeSelf ? (
    <Box
      className={cx({ [blinkingAnimationClass]: shouldBlinkSelf })}
      width="100%"
      minWidth={isOverlayOnboardingItem ? '290px' : undefined}
      borderRadius="16px"
      p="8px"
    >
      <Box
        width="100%"
        borderRadius="16px"
        background="#FFF"
        p="8px"
        boxShadow={
          !shouldBlinkSelf
            ? '0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.12)'
            : 'none'
        }
      >
        {item}
      </Box>
    </Box>
  ) : (
    item
  )
}

export default CreateProjectItem
