import React, { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import { useUserContext } from '../../contexts/UserContext'
import { useDispatch } from 'react-redux'
import { setUserOverride } from '../../redux/userSlice'
import RoutingPage from './RoutingPage'
import { Box } from '@chakra-ui/react'

const getVersionOverrideParamValue = (searchParams: URLSearchParams) => {
  const versionOverrideParam = searchParams.get('__version')
  return versionOverrideParam === 'v5'
    ? 'v5'
    : versionOverrideParam === 'v4'
      ? 'v4'
      : versionOverrideParam === 'v3'
        ? 'v3'
        : null
}

const MainPage: FC = () => {
  const dispatch = useDispatch()
  const { captureAnalytics } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const isAlpha = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]
  const isNewFolderCreationEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsNewFolderCreationEnabled]
  const [searchParams] = useSearchParams()
  const versionOverride = getVersionOverrideParamValue(searchParams)
  const ffMorningPageType = isAlpha ? 'v5' : isNewFolderCreationEnabled ? 'v4' : 'v3'
  const morningPageType = versionOverride || ffMorningPageType

  const userOverrideParam = searchParams.get('__user_override')

  useEffect(() => {
    if (userOverrideParam) {
      dispatch(setUserOverride(userOverrideParam))
    }
  }, [userOverrideParam, dispatch])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!window.document.hidden) {
        captureAnalytics('mainpage:view')
      }
    }
    window.document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [captureAnalytics])

  return (
    <Box display="flex" flexDirection="column" height="100vh" width="100vw">
      <RoutingPage morningPageType={morningPageType} />
    </Box>
  )
}

export default MainPage
