export enum WEBAPP_SESSION_STORAGE_KEYS {
  SkeemaUserBrowserSession = 'SkeemaUserBrowserSession',
}

export enum WEBAPP_LOCAL_STORAGE_KEYS {
  IsPinningTutorialVisible = 'IsPinningTutorialVisible',
  IsReadyForPinningTutorial = 'IsReadyForPinningTutorial',
  FeatureFlagValues = 'FeatureFlagValues',
  PlaceholderSessionTitleCount = 'PlaceholderSessionTitleCount',
  CurrentOnboardingTutorialStepV2 = 'CurrentOnboardingTutorialStepV2Fix',
  IsRebrandingNotificationDismissed = 'IsRebrandingNotificationDismissed',
  HasSkipperBrandingLoaded = 'HasSkipperBrandingLoaded',
  OnboardingGuidedAutoSaveSkipped = 'OnboardingGuidedAutoSaveSkipped',
  IsNewTabOverrideNotificationVisible = 'IsNewTabOverrideNotificationVisible',
  ShouldASTIncludeUserClosedTabs = 'ShouldASTIncludeUserClosedTabs',
  DEPRECATED_IsSurveyNotificationVisible = 'IsSurveyNotificationVisible',
  DEPRECATED_CurrentOnboardingTutorialStepV2 = 'CurrentOnboardingTutorialStepV2',
  DEPRECATED_CurrentOnboardingTutorialStep = 'CurrentOnboardingTutorialStep',
}
