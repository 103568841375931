import { createApi } from '@reduxjs/toolkit/query/react'
import { WEBAPP_BASE_URL } from '../../constants'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../extension/models/messaging.types'
import { sendMessageToExtension } from '../utils/externalMessaging'
import { TablistPageType } from '../../models/tablist_pages.types'
import { TabRecordType } from '../../extension/models/tabRecord.types'
import { createSelector } from '@reduxjs/toolkit'
import { ReduxDispatchType } from '../../redux/baseStore'
import { mapTabRecordToTablistPage } from '../../utils/tabUtils'

export interface ExtensionMessageQueryArgsType {
  action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS
  payload?: unknown
}

const extensionBaseQuery = async (args: ExtensionMessageQueryArgsType) => {
  const { action, payload } = args
  try {
    const data = await sendMessageToExtension(action, payload)
    return { data }
  } catch (error) {
    return { error }
  }
}

export const extensionMessageApi = createApi({
  reducerPath: 'extensionMessageApi',
  baseQuery: extensionBaseQuery,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllTabRecords: builder.query<TabRecordType[], undefined>({
      query: () => ({ action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_ALL_TABRECORDS }),
      transformResponse: (response) => {
        const tabRecords = response as (TabRecordType | null)[] | undefined
        const sorted = (tabRecords ?? [])
          .filter(
            (record): record is TabRecordType =>
              !!record &&
              (!record.url.startsWith(WEBAPP_BASE_URL) ||
                record.url.startsWith(`${WEBAPP_BASE_URL}/projects`) ||
                record.url.startsWith(`${WEBAPP_BASE_URL}/folders`)),
          )
          .sort((r1, r2) =>
            r1.windowId === r2.windowId ? r1.index - r2.index : r1.windowId - r2.windowId,
          )
        return sorted
      },
    }),
    getOpenTabs: builder.query<TablistPageType[], undefined>({
      query: () => ({ action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_ALL_TABRECORDS }),
      transformResponse: (response) => {
        const tabRecords = response as (TabRecordType | null)[] | undefined
        const tabPages = (tabRecords ?? [])
          .filter(
            (record): record is TabRecordType =>
              !!record &&
              (!record.url.startsWith(WEBAPP_BASE_URL) ||
                record.url.startsWith(`${WEBAPP_BASE_URL}/projects`) ||
                record.url.startsWith(`${WEBAPP_BASE_URL}/folders`)),
          )
          .sort((r1, r2) =>
            r1.windowId === r2.windowId ? r1.index - r2.index : r1.windowId - r2.windowId,
          )
          .map((record) => {
            return mapTabRecordToTablistPage(record)
          })
        return tabPages
      },
    }),
    getTabGroups: builder.query<chrome.tabGroups.TabGroup[], undefined>({
      query: () => ({ action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_TAB_GROUPS }),
      transformResponse: (response) => {
        const tabGroups = response as chrome.tabGroups.TabGroup[] | undefined
        return tabGroups ?? []
      },
    }),
    deleteOpenTabs: builder.mutation<boolean, { ids: string[] }>({
      query: ({ ids }) => ({
        action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.CLOSE_TABS,
        payload: { tabIds: ids },
      }),
      transformResponse: () => true,
      transformErrorResponse: () => false,
      onQueryStarted: (params, { dispatch, queryFulfilled }) => {
        const { ids } = params
        const response = optimisticRemoveOpenTabs(dispatch, ids)
        queryFulfilled.catch(response.undo)
      },
    }),
  }),
})

export function optimisticRemoveOpenTabs(dispatch: ReduxDispatchType, tabIds: string[]) {
  return dispatch(
    extensionMessageApi.util.updateQueryData(
      'getOpenTabs',
      undefined,
      (draft: TablistPageType[]) => {
        tabIds.forEach((id) => {
          const idx = draft.findIndex((page) => page.id === id)
          if (idx !== -1) {
            draft.splice(idx, 1)
          }
        })
      },
    ),
  )
}

const selectOpenTabsQueryResult = extensionMessageApi.endpoints.getOpenTabs.select(undefined)
export const selectOpenTabs = createSelector(selectOpenTabsQueryResult, (result) => {
  return result.data
})

export const {
  useGetOpenTabsQuery,
  useLazyGetOpenTabsQuery,
  useGetTabGroupsQuery,
  useGetAllTabRecordsQuery,
  useDeleteOpenTabsMutation,
} = extensionMessageApi
