import React, { FC } from 'react'
import AnnouncementsPage from '../../components/AnnouncementsPage'
import { isMobileOrTabletDevice } from '../../utils/deviceUtils'

const RequireDesktop: FC<{ children: React.JSX.Element }> = ({ children }) => {
  if (isMobileOrTabletDevice) {
    return (
      <AnnouncementsPage
        heading={"This page doesn't support mobile.  For now. 😉"}
        content={['Please use your desktop browser to access Skipper.']}
      />
    )
  }
  return children
}

export default React.memo(RequireDesktop)
