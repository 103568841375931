import React, { FC, useCallback, useRef, useState } from 'react'
import { Text, Flex, Box, ToastId, useToast, UseToastOptions, Collapse } from '@chakra-ui/react'
import { styled } from 'styled-components'
import PutasideTabController from './PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { useDeleteOpenTabsMutation, useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import NotificationToast from '../../../components/NotificationToast'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useAppendTablistPagesMutation } from '../../../redux/services/skeema/tablist_pages.endpoints'
import {
  selectIsFolderCreationModeActive,
  selectPendingFolderCreationPages,
} from '../../../redux/projectsSlice'
import { useReduxSelector } from '../../../redux/baseStore'
import { FiChevronDown } from 'react-icons/fi'
import FavIconList, { FavIconListItemType } from '../FavIconList'
import { useUserContext } from '../../../contexts/UserContext'

const CollapseIcon = styled.span<{ $isCollapsed: boolean }>`
  height: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.2em;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  width: 16px;
  color: black;

  & > svg {
    display: flex;
    transform: ${({ $isCollapsed }) => `rotate(${$isCollapsed ? -90 : 0}deg)`};
    transition: transform 0.5s;
    transform-origin: 50% 50%;
  }
`

const TOAST_DURATION_MS = 3000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface Props {
  isSmartSessionOnboardingActive: boolean
  isCollapsed: boolean
}

const OpenTabsSection: FC<Props> = (props) => {
  const { isSmartSessionOnboardingActive, isCollapsed } = props
  const { captureAnalytics } = useUserContext()
  const [isOpenTabsCollapsed, setIsOpenTabsCollapsed] = useState<boolean>(isCollapsed)

  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)
  const pendingFolderCreationPages = useReduxSelector(selectPendingFolderCreationPages)

  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const [appendTablistPages] = useAppendTablistPagesMutation()
  const [deleteOpenTabs] = useDeleteOpenTabsMutation()
  const deleteOpenTab = useCallback(
    async (params: { id: string }) => {
      await deleteOpenTabs({ ids: [params.id] }).unwrap()
    },
    [deleteOpenTabs],
  )

  const saveAndCloseOpenTab = useCallback(
    async (params: { page: TablistPageType }) => {
      const { page } = params
      const id = page.id

      await appendTablistPages({ pages: [page], isManual: true })
        .unwrap()
        .then((res) => {
          if (res.ok) {
            deleteOpenTab({ id })
          }
        })
        .catch((error) => {
          console.error(error)
        })

      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
        toastIdRef.current = undefined
      }
      toastIdRef.current = toast({
        duration: TOAST_DURATION_MS,
        containerStyle: { minWidth: 'auto' },
        render: () => <NotificationToast message={`Tab has been saved to 'All saved tabs'`} />,
      })
    },
    [appendTablistPages, deleteOpenTab, toast],
  )

  const handleClickOpenTabsSection = useCallback(() => {
    setIsOpenTabsCollapsed((prev) => {
      captureAnalytics('putaside_tab_list:open_tabs_section_collapse', {
        isCollapsed: !prev,
      })
      return !prev
    })
  }, [captureAnalytics])

  const handleClickExpandFavIcons = useCallback(() => {
    setIsOpenTabsCollapsed(false)
    captureAnalytics('putaside_tab_list:open_tabs_section_collapse', {
      isCollapsed: false,
      isFavIconsClicked: true,
    })
  }, [captureAnalytics])

  if (!openTabPages || openTabPages.length === 0) {
    return null
  }

  return (
    <Box mb="16px">
      <Flex alignItems="center" justifyContent="space-between" marginBottom="8px" marginLeft="12px">
        <Flex
          alignItems="center"
          cursor={!isSmartSessionOnboardingActive ? 'pointer' : 'default'}
          onClick={isSmartSessionOnboardingActive ? undefined : handleClickOpenTabsSection}
        >
          <Text as="h2" color="#000" fontSize="16px" fontWeight="600" lineHeight="24px">
            Open tabs
          </Text>
          <CollapseIcon $isCollapsed={!!isOpenTabsCollapsed}>
            <FiChevronDown />
          </CollapseIcon>
        </Flex>
      </Flex>

      <Box
        flex="1 2 auto"
        display={isOpenTabsCollapsed ? 'flex' : 'none'}
        alignItems="center"
        justifyContent="flex-start"
        overflow="auto"
        minWidth="24px"
        maxWidth="100%"
        padding="0 6px"
      >
        <FavIconList
          items={openTabPages.map((t) => {
            return {
              id: t.id,
              url: t.url,
              faviconUrl: t.favicon_url,
              title: t.title,
              windowId: t.window_id,
              isOpen: true,
              tabId: parseInt(t.id),
            } as FavIconListItemType
          })}
          isClickable={true}
          areaName={TABLIST_AREA_NAME_ENUM.Open}
          size="md"
          showTitleTooltip={true}
          maxLength={8}
          onClickExpand={handleClickExpandFavIcons}
        />
      </Box>

      <Collapse in={!isOpenTabsCollapsed} animateOpacity>
        <Box>
          {openTabPages?.map((t, idx) => {
            return (
              <PutasideTabController
                key={t.id}
                id={t.id}
                page={t}
                onDeleteOpenTab={deleteOpenTab}
                shouldShowDeleteIcon={true}
                isSelectionDisabled={isFolderCreationModeActive}
                shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
                isPendingInFolderCreation={pendingFolderCreationPages.some((p) => p.id === t.id)}
                onSaveOpenTab={saveAndCloseOpenTab}
                showTimeString={false}
                queryValue={''}
                index={idx}
                numTotalResults={openTabPages.length}
                areaName={TABLIST_AREA_NAME_ENUM.Open}
              />
            )
          })}
        </Box>
      </Collapse>
    </Box>
  )
}

export default OpenTabsSection
