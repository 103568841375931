import React, { FC, useEffect, useState } from 'react'
import { Collapse, Box, Flex, Heading, Icon } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useReduxSelector } from '../../../redux/baseStore'
import { selectSearchQueryValue } from '../../../redux/searchSlice'
import { cx, css } from '@emotion/css'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { MdOutlineInbox } from 'react-icons/md'
import SavedForLaterList from './SavedForLaterList'
import OpenTabsSection from './OpenTabsSection'
import { TablistPageType } from '../../../models/tablist_pages.types'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
  FolderCreation = 'folder_creation',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const onboardingPrepAnimationClassName = css`
  transition: opacity 2000ms ease-in-out 500ms;
  opacity: 0;
`

const onboardingAnimationClassName = css`
  opacity: 1;
`

interface Props {
  isOnboardingTutorialEnabled?: boolean
  isSmartSessionOnboardingActive?: boolean
  isSavedTabsOnboardingStepActive?: boolean
}

const PutasideTabList: FC<Props> = (props) => {
  const {
    isOnboardingTutorialEnabled = false,
    isSmartSessionOnboardingActive = false,
    isSavedTabsOnboardingStepActive = false,
  } = props

  const queryValue = useReduxSelector(selectSearchQueryValue)

  const isSearchingActive = queryValue.trim().length > 0
  const isResultsModeEnabled = isSearchingActive

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(isOnboardingTutorialEnabled)
    }, 500)
  }, [isOnboardingTutorialEnabled])

  return (
    <Box
      className={cx({
        [onboardingPrepAnimationClassName]: isOnboardingTutorialEnabled,
        [onboardingAnimationClassName]: shouldAnimate,
        scrollbars: isSavedTabsOnboardingStepActive,
      })}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      overflowY={isSavedTabsOnboardingStepActive ? 'auto' : 'hidden'}
      borderRadius={isSavedTabsOnboardingStepActive ? '16px 16px 0px 0px' : undefined}
      backgroundColor={isSavedTabsOnboardingStepActive ? '#f6f6f6' : undefined}
    >
      <Box p="24px 16px">
        <SearchResultsReplacementWrapper>
          <Collapse in={!isResultsModeEnabled} animateOpacity>
            <OpenTabsSection
              isSmartSessionOnboardingActive={isSmartSessionOnboardingActive}
              isCollapsed={!isSavedTabsOnboardingStepActive}
            />

            <SectionContainer>
              <Box mb="12px">
                <Flex m="0px 0px" alignItems="center" position="relative" zIndex={2}>
                  <Box p="10px 10px 0 10px">
                    <Flex
                      p="0 2px 6px 2px"
                      alignItems="center"
                      borderBottom="2px solid"
                      borderBottomColor="#000"
                      color="#000"
                      transition="all 0.2s"
                      gap="6px"
                    >
                      <Icon as={MdOutlineInbox} width="24px" height="24px" />
                      <Heading
                        as="h2"
                        color="inherit"
                        fontSize="16px"
                        fontWeight={600}
                        lineHeight="24px"
                      >
                        All saved tabs
                      </Heading>
                    </Flex>
                  </Box>
                </Flex>

                <Box
                  borderBottom="1px solid #D5D5D5"
                  margin="0px 8px 0px 10px"
                  position="relative"
                  top="-1px"
                  zIndex={1}
                />
              </Box>

              <SavedForLaterList />
            </SectionContainer>
          </Collapse>
        </SearchResultsReplacementWrapper>
      </Box>
    </Box>
  )
}

export default PutasideTabList
