import React, { FC } from 'react'
import SVG from './SVG'

const CurvedArrowAltSVG: FC<React.SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <SVG
      width="24"
      height="56"
      viewBox="0 0 24 56"
      stroke="none"
      fill="none"
      color="#0071E3"
      {...props}
    >
      <g id="Arrow 04" clipPath="url(#clip0_4567_118151)">
        <path
          id="Arrow 04_2"
          d="M6.45986 56C5.03951 55.325 4.16025 55.19 3.88971 54.7176C2.87517 52.9627 1.72535 51.2078 1.18427 49.2504C-0.438998 44.0532 -0.23609 38.7886 0.846086 33.5239C2.67226 24.5469 6.7304 16.5824 11.8031 9.02291C12.3442 8.21296 12.8177 7.403 13.3587 6.52555C13.3587 6.45806 13.2911 6.32306 13.1558 6.12057C11.6678 6.52555 10.1122 6.93053 8.62422 7.26801C7.81259 7.403 6.86568 7.403 6.05404 7.13301C5.58059 6.99802 4.90425 6.25557 4.90425 5.7831C4.90425 5.17563 5.37769 4.43319 5.7835 3.89322C6.12168 3.55574 6.73042 3.48825 7.2715 3.35326C11.3297 2.34083 15.3878 1.19339 19.5136 0.248452C23.0307 -0.561498 24.1805 0.585935 23.9776 4.02822C23.7747 7.6055 23.7071 11.1828 23.5718 14.6926C23.5041 15.7725 23.3012 16.9199 23.166 18.1348C20.3252 16.8524 20.3252 16.8524 19.3107 5.9181C15.0496 10.6428 12.2089 15.7725 9.57112 21.0372C6.93332 26.3018 5.17479 31.8365 4.22789 37.7086C3.28098 43.7832 4.43079 49.5204 6.45986 56Z"
          fill="currentColor"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_4567_118151">
          <rect width="24" height="56" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default CurvedArrowAltSVG
