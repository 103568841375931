import React, { FC } from 'react'
import LoadingPage from './LoadingPage'
import { useAuthContext } from '../contexts/AuthContext'
import { useUserContext } from '../contexts/UserContext'
import { useLocation } from 'react-router-dom'

interface Props {
  children: React.ReactNode
  shouldWaitForAuth: boolean
  shouldWaitForUserInfo: boolean
}

const _LoadingToggle: FC<{ isLoading: boolean; children: React.ReactNode }> = (props) => {
  const { isLoading, children } = props
  return isLoading ? <LoadingPage /> : children
}
const LoadingToggle = React.memo(_LoadingToggle)

const AppLoadingWrapper: FC<Props> = (props) => {
  const { children, shouldWaitForAuth, shouldWaitForUserInfo } = props
  const { isAuthLoading } = useAuthContext()
  const { isUserInfoLoading } = useUserContext()
  const location = useLocation()
  const shouldDisableLoading = location.pathname === '/auth/handler'
  const isLoading =
    (shouldWaitForAuth && isAuthLoading) || (shouldWaitForUserInfo && isUserInfoLoading)

  //Memo'd LoadingToggle prevents re-renders of the children when this component rerenders but the loading state doesn't change
  return <LoadingToggle isLoading={isLoading && !shouldDisableLoading}>{children}</LoadingToggle>
}

export default AppLoadingWrapper
