import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'

interface Props {
  onNext: () => void
}
const OnboardingPrefaceDialog: FC<Props> = (props) => {
  const { onNext } = props

  return (
    <OnboardingStepTemplate
      heading={
        <>
          {`Welcome to onboarding!`}
          <br />
          {`Skipper helps with 3 things:`}
          <br />
          <br />
          {`1. Decluttering your tabs 🧹`}
          <br />
          {`2. Organizing them into folders 🗂️`}
          <br />
          {`3. Opening them when you're ready 🚀`}
        </>
      }
      buttonText={`Next`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingPrefaceDialog
