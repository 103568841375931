export enum ONBOARDING_TUTORIAL_STEPS {
  Preface = 'Preface',
  Intro = 'Intro',
  PinTabs = 'PinTabs',
  Stats = 'Stats',
  GuidedAutoSave = 'GuidedAutoSave',
  AutoSaveConfig = 'AutoSaveConfig',
  TabList = 'TabList',
  NewFolderButton = 'NewFolderButton',
  FolderCreation = 'FolderCreation',
  FolderCreated = 'FolderCreated',
  Conclusion = 'Conclusion',
  DONE = 'DONE_V2',
}

export const VALID_ONBOARDING_TUTORIAL_STEPS: {
  //The boolean value is redundant, but we use an object here
  //so that typescript will ensure every enum value is present
  [key in ONBOARDING_TUTORIAL_STEPS]: boolean
} = {
  [ONBOARDING_TUTORIAL_STEPS.Preface]: true,
  [ONBOARDING_TUTORIAL_STEPS.Stats]: true,
  [ONBOARDING_TUTORIAL_STEPS.Intro]: true,
  [ONBOARDING_TUTORIAL_STEPS.PinTabs]: true,
  [ONBOARDING_TUTORIAL_STEPS.GuidedAutoSave]: true,
  [ONBOARDING_TUTORIAL_STEPS.TabList]: true,
  [ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig]: true,
  [ONBOARDING_TUTORIAL_STEPS.NewFolderButton]: true,
  [ONBOARDING_TUTORIAL_STEPS.FolderCreation]: true,
  [ONBOARDING_TUTORIAL_STEPS.FolderCreated]: true,
  [ONBOARDING_TUTORIAL_STEPS.Conclusion]: true,
  [ONBOARDING_TUTORIAL_STEPS.DONE]: true,
}
