import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import PutasideTabView, { PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM } from './PutasideTabView'
import {
  DND_ITEM_ENUM,
  DnDItemPayload,
  DnDItemTablistPagePayload,
  DnDItemTablistPagesPayload,
} from '../../../models/dnd.types'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { setIsTablistPageDragging } from '../../../redux/dndSlice'
import { shouldOpenInNewTab } from '../../../utils/utils'
import { MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import webappStore from '../../../webapp/redux/webappStore'
import { TablistPageTypeWithOrder } from '../../../redux/selectedPagesSlice'
import { getEmptyImage } from 'react-dnd-html5-backend'
import {
  addPendingFolderCreationPages,
  reorderPendingFolderCreationPage,
  removePendingFolderCreationPage,
} from '../../../redux/projectsSlice'

interface PropTypes {
  id: string
  page: TablistPageType
  queryValue: string
  index: number
  numTotalResults: number
  areaName: TABLIST_AREA_NAME_ENUM
  shouldDisplayLocation?: boolean
  shouldShowPinIconForCheckbox?: boolean
  onDeleteTablistPage?: (params: { page: TablistPageType; index: number }) => Promise<void>
  onDeleteOpenTab?: (params: { id: string }) => Promise<void>
  onDeleteSmartSessionPage?: (params: { page: TablistPageType }) => Promise<void>
  onSaveOpenTab?: (params: { page: TablistPageType }) => Promise<void>
  onOpenMoveMenu?: (params: { pageInfo: MoveTablistPageInfoType }) => void
  onSelected?: (params: {
    clickedItemId: string
    isShiftKey: boolean
    isCtrlOrCmdKey: boolean
    isCurrentlySelected: boolean
    extraAnalyticsProps?: Record<string, unknown>
  }) => void
  removeSelection?: (params: { pageId: string }) => void
  isSelected?: boolean
  isTabAboveSelected?: boolean
  isTabBelowSelected?: boolean
  isCheckboxShown?: boolean
  isFocused?: boolean
  showOpenTabIcon?: boolean
  titleHighlights?: [number, number][]
  showTimeString?: boolean
  showActionIcons?: boolean
  isSelectionDisabled?: boolean
  isHoverDisabled?: boolean
  isTitleClickDisabled?: boolean
  isDraggingDisabled?: boolean
  isDragIconPlaceholderHidden?: boolean
  showAiSuggestion?: boolean
  extraAnalyticsProps?: Record<string, unknown>
  isHighlighted?: boolean
  styleVariant?: PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM
  shouldShowFolderCreationAddIcon?: boolean
  isPendingInFolderCreation?: boolean
  deleteIconTooltip?: string
  shouldShowDeleteIcon?: boolean
  shouldForceHighlightTop?: boolean
  shouldForceHighlightBottom?: boolean
  isProjectPageLimitReached?: boolean
  numProjectPages?: number
  maxNumProjectPages?: number
}

const PutasideTabController: FC<PropTypes> = (props) => {
  const {
    id,
    page,
    onDeleteTablistPage,
    onDeleteOpenTab,
    onDeleteSmartSessionPage,
    onSaveOpenTab,
    onOpenMoveMenu,
    onSelected,
    removeSelection,
    titleHighlights,
    queryValue,
    index,
    numTotalResults,
    isTabAboveSelected = false,
    isTabBelowSelected = false,
    isSelected = false,
    shouldDisplayLocation = false,
    isCheckboxShown = false,
    isFocused = false,
    showOpenTabIcon = false,
    isSelectionDisabled = false,
    isHoverDisabled = false,
    isTitleClickDisabled = false,
    isDraggingDisabled = false,
    showTimeString = true,
    showActionIcons = true,
    shouldShowPinIconForCheckbox = false,
    showAiSuggestion = false,
    isDragIconPlaceholderHidden = false,
    isHighlighted = false,
    styleVariant = PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.DEFAULT,
    areaName,
    extraAnalyticsProps = {},
    shouldShowFolderCreationAddIcon = false,
    isPendingInFolderCreation = false,
    deleteIconTooltip,
    shouldShowDeleteIcon = false,
    shouldForceHighlightTop = false,
    shouldForceHighlightBottom = false,
    isProjectPageLimitReached = false,
    numProjectPages = 0,
    maxNumProjectPages = 999,
  } = props
  const { captureAnalytics } = useUserContext()
  const { projectConfig } = useFeatureFlagContext()
  const dispatch = useReduxDispatch()
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const tabContainerRef = useRef<HTMLDivElement | null>(null)
  const componentName = `tablist_${areaName}`

  useEffect(() => {
    if (isFocused && tabContainerRef.current) {
      tabContainerRef.current.scrollIntoView({ block: 'nearest' })
    }
  }, [isFocused])

  const handleClickFolderCreationAddIcon = useCallback(() => {
    if (!shouldShowFolderCreationAddIcon) {
      return
    }

    if (isPendingInFolderCreation) {
      return
    }

    dispatch(addPendingFolderCreationPages({ pages: [page] }))

    captureAnalytics(`${componentName}:folder_creation_add_icon_clicked`, {
      title: page.title,
      url: page.url,
      location: page.location,
      entityType: page.entity_type,
      queryValue,
      index,
      numTotalResults,
      isSelected: !isSelected,
      ...extraAnalyticsProps,
    })
  }, [
    shouldShowFolderCreationAddIcon,
    isPendingInFolderCreation,
    dispatch,
    page,
    captureAnalytics,
    componentName,
    queryValue,
    index,
    numTotalResults,
    isSelected,
    extraAnalyticsProps,
  ])

  const handleClickSelect = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (isSelectionDisabled || !onSelected) {
        return
      }

      captureAnalytics(`${componentName}:tab_selection_toggle`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,
        isSelected: !isSelected,
        ...extraAnalyticsProps,
      })

      onSelected({
        clickedItemId: id,
        isShiftKey: event.shiftKey,
        isCtrlOrCmdKey: event.ctrlKey || event.metaKey,
        isCurrentlySelected: isSelected,
      })
    },
    [
      isSelectionDisabled,
      onSelected,
      captureAnalytics,
      componentName,
      page.title,
      page.url,
      page.location,
      page.entity_type,
      queryValue,
      index,
      numTotalResults,
      isSelected,
      extraAnalyticsProps,
      id,
    ],
  )

  const handleClickTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()

      captureAnalytics(`${componentName}:tab_title_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      if (page.is_open) {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SWITCH_TO_TAB, {
          tabId: parseInt(page.id),
          windowId: page.window_id,
        })
      } else {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
          urls: [page.url],
          shouldActivate: !shouldOpenInNewTab(event),
        })
      }
    },
    [
      captureAnalytics,
      componentName,
      page.title,
      page.url,
      page.location,
      page.entity_type,
      page.is_open,
      page.id,
      page.window_id,
      queryValue,
      index,
      numTotalResults,
      extraAnalyticsProps,
    ],
  )

  const handleClickDeleteIcon = useCallback(
    async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (!page.entity_type) {
        return
      }

      captureAnalytics(`${componentName}:delete_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      const idToRemove = page.instance_id ?? page.id
      if (areaName === TABLIST_AREA_NAME_ENUM.FolderCreation && idToRemove) {
        dispatch(removePendingFolderCreationPage(String(idToRemove)))
        return
      }

      if (page.entity_type === TABLIST_PAGE_ENUM.OPEN && !!onDeleteOpenTab) {
        await onDeleteOpenTab({ id })
      } else if (
        page.entity_type === TABLIST_PAGE_ENUM.SMART_SESSION &&
        !!onDeleteSmartSessionPage
      ) {
        await onDeleteSmartSessionPage({ page })
      } else if (page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED && !!onDeleteTablistPage) {
        await onDeleteTablistPage({ page, index })
      } else {
        return
      }

      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [
      page,
      captureAnalytics,
      componentName,
      queryValue,
      index,
      numTotalResults,
      extraAnalyticsProps,
      areaName,
      onDeleteOpenTab,
      onDeleteSmartSessionPage,
      onDeleteTablistPage,
      isSelected,
      removeSelection,
      dispatch,
      id,
    ],
  )

  const handleClickSaveIcon = useCallback(
    async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (!page.entity_type || page.entity_type !== TABLIST_PAGE_ENUM.OPEN || !onSaveOpenTab) {
        return
      }

      captureAnalytics(`${componentName}:save_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      await onSaveOpenTab({ page })
      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [
      page,
      onSaveOpenTab,
      captureAnalytics,
      componentName,
      queryValue,
      index,
      numTotalResults,
      extraAnalyticsProps,
      isSelected,
      removeSelection,
    ],
  )

  const handleClickMoveIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (
        !page.entity_type ||
        page.entity_type !== TABLIST_PAGE_ENUM.RECENTLY_USED ||
        !onOpenMoveMenu
      ) {
        return
      }

      captureAnalytics(`${componentName}:move_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      onOpenMoveMenu({ pageInfo: { type: 'TABLIST_PAGE', page, index } })
      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [
      page,
      onOpenMoveMenu,
      captureAnalytics,
      componentName,
      queryValue,
      index,
      numTotalResults,
      extraAnalyticsProps,
      isSelected,
      removeSelection,
    ],
  )

  const [{ isDraggingThis }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.TABLIST_PAGES,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      canDrag: () => !isDraggingDisabled,
      end: () => dispatch(setIsTablistPageDragging(false)),
      item: () => {
        dispatch(setIsTablistPageDragging(true))

        const payloads: DnDItemTablistPagePayload[] = []

        if (isSelected) {
          const selectedPages: TablistPageTypeWithOrder[] = []
          if (areaName === TABLIST_AREA_NAME_ENUM.RecentlySaved) {
            selectedPages.push(
              ...Object.values(webappStore.getState().selectedPages.selectedSavedForLaterPages),
            )
          } else if (areaName === TABLIST_AREA_NAME_ENUM.SmartSessions) {
            selectedPages.push(
              ...Object.values(webappStore.getState().selectedPages.selectedSmartSessionPages),
            )
          }

          payloads.push(
            ...selectedPages.map(
              (p): DnDItemTablistPagePayload => ({
                type: DND_ITEM_ENUM.TABLIST_PAGE,
                page: p,
                index: p.order,
              }),
            ),
          )
        } else {
          payloads.push({
            type: DND_ITEM_ENUM.TABLIST_PAGE,
            page,
            index,
          })
        }

        payloads.sort((a, b) => a.index - b.index)

        const payload: DnDItemTablistPagesPayload = {
          type: DND_ITEM_ENUM.TABLIST_PAGES,
          payloads,
        }

        return payload
      },
    }),
    [id, page, dispatch, isDraggingDisabled, index, isSelected],
  )

  useEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [connectDragPreview])

  const [dragOverLocation, setDragOverLocation] = useState<'top' | 'bottom' | undefined>(undefined)
  const dragOverLocationRef = useRef<'top' | 'bottom' | undefined>(undefined)
  useEffect(() => {
    dragOverLocationRef.current = dragOverLocation
  }, [dragOverLocation])

  const [{ isDraggingOverThis }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => ({
        isDraggingOverThis: monitor.isOver(),
      }),
      canDrop: (pl: DnDItemPayload) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        if (page.entity_type !== TABLIST_PAGE_ENUM.FOLDER_CREATION) {
          return false
        }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE &&
          payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION
        ) {
          if (payload.page.id === page.id) {
            return false
          }
          const isSameLocation =
            (payload.index === index - 1 && dragOverLocationRef.current === 'top') ||
            (payload.index === index + 1 && dragOverLocationRef.current === 'bottom')
          if (isSameLocation) {
            return false
          }
          return true
        } else if (payload.type === DND_ITEM_ENUM.TABLIST_PAGE) {
          if (isProjectPageLimitReached) {
            return false
          }
          return true
        } else if (
          payload.type === DND_ITEM_ENUM.SMART_SESSION ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const numAddedPages =
            payload.type === DND_ITEM_ENUM.SMART_SESSION
              ? payload.session.pages.length
              : payload.payloads.length
          const newNumPages = numProjectPages + numAddedPages
          if (newNumPages > maxNumProjectPages) {
            return false
          }
          return true
        }

        return false
      },
      hover: (pl: DnDItemPayload, monitor) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        if (page.entity_type !== TABLIST_PAGE_ENUM.FOLDER_CREATION) {
          return
        }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE &&
          payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION &&
          payload.page.id === page.id
        ) {
          return
        }

        const { y } = monitor.getClientOffset() ?? {}
        const { height, top } = tabContainerRef.current?.getBoundingClientRect() ?? {}
        const middleY = height && top ? top + height / 2 : undefined

        if (middleY === undefined || y === undefined) {
          setDragOverLocation(undefined)
          return
        }

        const newDragLocation = middleY > y ? 'top' : 'bottom'

        const isSameProject =
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE &&
          payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION
        if (isSameProject) {
          const isSameLocation =
            (payload.index === index - 1 && newDragLocation === 'top') ||
            (payload.index === index + 1 && newDragLocation === 'bottom')
          if (isSameLocation) {
            setDragOverLocation(undefined)
            return
          }
        }

        setDragOverLocation(newDragLocation)
      },
      drop: (pl: DnDItemPayload, monitor) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        if (page.entity_type !== TABLIST_PAGE_ENUM.FOLDER_CREATION) {
          return
        }

        if (monitor.didDrop()) {
          //Another drop target received the drop event already
          return { status: 'DID_DROP' }
        }

        const dropIndex = dragOverLocationRef.current === 'top' ? index : index + 1

        if (payload.type === DND_ITEM_ENUM.TABLIST_PAGE) {
          if (payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION) {
            dispatch(reorderPendingFolderCreationPage({ page: payload.page, newIndex: dropIndex }))
          } else {
            dispatch(addPendingFolderCreationPages({ pages: [payload.page], index: dropIndex }))
          }
          return { status: 'SUCCESS' }
        } else if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES ||
          payload.type === DND_ITEM_ENUM.SMART_SESSION
        ) {
          const newPages =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES
              ? payload.payloads.map((p) => p.page)
              : payload.session.pages
          dispatch(addPendingFolderCreationPages({ pages: newPages, index: dropIndex }))

          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [page, index, dispatch, isProjectPageLimitReached, maxNumProjectPages, numProjectPages],
  )

  useEffect(() => {
    if (!isDraggingOverThis) {
      setDragOverLocation(undefined)
    }
  }, [isDraggingOverThis])

  const connectDnd = useCallback(
    (node: HTMLDivElement | null) => {
      tabContainerRef.current = node
      connectDragSource(connectDropTarget(tabContainerRef))
    },
    [connectDragSource, connectDropTarget],
  )

  return (
    <PutasideTabView
      location={page.location}
      entityType={page.entity_type}
      title={
        page.custom_title && page.custom_title.length > 0
          ? page.custom_title
          : page.title ?? 'Untitled Page'
      }
      favIconUrl={page.favicon_url}
      showAiSuggestion={showAiSuggestion}
      aiSuggestionReason={page.ai_suggestion?.reason}
      lastAccessDateTime={page.last_access_datetime_utc_iso}
      showTimeString={showTimeString}
      titleHighlights={titleHighlights}
      isTitleClickDisabled={
        isTitleClickDisabled || isPendingInFolderCreation || shouldShowFolderCreationAddIcon
      }
      showOpenTabIcon={showOpenTabIcon}
      isPinnedTab={page.is_pinned}
      handleClickCheckbox={handleClickSelect}
      handleClickFavIcon={handleClickTitle}
      handleClickDragIcon={handleClickSelect}
      handleClickDeleteIcon={handleClickDeleteIcon}
      shouldShowDeleteIcon={shouldShowDeleteIcon}
      handleClickSaveIcon={
        page.entity_type === TABLIST_PAGE_ENUM.OPEN && onSaveOpenTab
          ? handleClickSaveIcon
          : undefined
      }
      handleClickMoveIcon={
        page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED && onOpenMoveMenu
          ? handleClickMoveIcon
          : undefined
      }
      handleClickTitle={handleClickTitle}
      handleClickBody={handleClickSelect}
      isTabAboveSelected={isTabAboveSelected}
      isTabBelowSelected={isTabBelowSelected}
      isSelected={isSelected}
      isFocused={isFocused}
      isSelectionDisabled={isSelectionDisabled}
      isHoverDisabled={isHoverDisabled}
      isSelectionDisabledTooltipLabel={`You cannot select more than ${projectConfig.maxNumProjectsPages} pages`}
      isCheckboxShown={isCheckboxShown}
      isDragIconPlaceholderHidden={isDragIconPlaceholderHidden}
      isDraggingThis={isDraggingThis || (isSelected && isTablistPageDragging)}
      isDraggingDisabled={isDraggingDisabled}
      isHighlighted={isHighlighted}
      connectDnD={!isDraggingDisabled ? connectDnd : undefined}
      moveIconTooltip={'Move'}
      titleToolTip={
        page.entity_type === TABLIST_PAGE_ENUM.OPEN ? (
          'Go to Tab'
        ) : page.custom_title ? (
          <span style={{ fontSize: '12px' }}>
            Original title: {page.title}
            <br /> <br />
            URL: {page.url}
          </span>
        ) : (
          page.url
        )
      }
      deleteIconToolTip={
        deleteIconTooltip ??
        (areaName === TABLIST_AREA_NAME_ENUM.FolderCreation ||
          page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED ||
          page.entity_type === TABLIST_PAGE_ENUM.SMART_SESSION)
          ? 'Remove'
          : page.entity_type === TABLIST_PAGE_ENUM.OPEN
            ? 'Close tab'
            : undefined
      }
      saveIconTooltip={onSaveOpenTab ? 'Save & close' : undefined}
      showActionIcons={!shouldShowFolderCreationAddIcon && showActionIcons}
      shouldShowPinIconForCheckbox={shouldShowPinIconForCheckbox}
      shouldDisplayLocation={shouldDisplayLocation}
      styleVariant={styleVariant}
      shouldShowFolderCreationAddIcon={shouldShowFolderCreationAddIcon}
      handleClickFolderCreationAddIcon={handleClickFolderCreationAddIcon}
      isPendingInFolderCreation={isPendingInFolderCreation}
      shouldHighlightTop={shouldForceHighlightTop || dragOverLocation === 'top'}
      shouldHighlightBottom={shouldForceHighlightBottom || dragOverLocation === 'bottom'}
    />
  )
}

export default React.memo(PutasideTabController)
