import { ActionQuotaType, UserInfoType } from './user.types'
import { UserEventUpdateType } from './user_events.types'
import {
  ProjectCreationType,
  ProjectPageCreationType,
  ProjectPageType,
  ProjectPermissionsType,
  ProjectType,
} from './saved_sessions.types'
import { UpcomingPageType } from './upcoming_pages.types'
import {
  TablistPageTopicGroupType,
  TablistPageSearchResultType,
  TablistPageType,
} from './tablist_pages.types'
import { AnalyticsEventType } from './analytics.types'
import { SmartSessionType } from './smart_sessions.types'
import { AIActionLogType } from './ai_action_logs.types'

export const USER_EVENT_UPDATE_ENDPOINT_PATH = '/api/v1/events/'
export const USER_INFO_ENDPOINT_PATH = '/api/v1/users/current_user/'
export const USER_RESET_ENDPOINT_PATH = (user_id: string) => `/api/v1/users/${user_id}/reset/`
export const ANALYTICS_ENDPOINT_PATH = '/api/v1/users/analytics/'
export const EXCHANGE_GOOGLE_AUTH_CODE_ENDPOINT_PATH = '/dj-rest-auth/google/'
export const MAGIC_MOMENT_ENDPOINT_PATH = '/api/v1/activity_periods/magic_moments/'
export const PROJECTS_ENDPOINT_PATH = '/api/v1/saved_sessions/'
export const PROJECTS_RECOMMEND_ENDPOINT_PATH = '/api/v1/saved_sessions/recommend/'
export const PROJECT_ENDPOINT_PATH = (saved_session_pk: string) =>
  `/api/v1/saved_sessions/${saved_session_pk}/`
export const PROJECT_PAGES_ENDPOINT_PATH = (saved_session_pk: string) =>
  `/api/v1/saved_sessions/${saved_session_pk}/pages/`
export const PROJECT_PERMISSIONS_ENDPOINT_PATH = (saved_session_pk: string) =>
  `/api/v1/saved_sessions/${saved_session_pk}/permissions/`
export const UPCOMING_PAGE_ENDPOINT_PATH = '/api/v1/upcoming_pages/'
export const TABLIST_PAGES_ENDPOINT_PATH = '/api/v1/tablist_pages/'
export const ALL_SAVED_TABS_ENDPOINT_PATH = '/api/v1/all_saved_pages/'
export const TABLIST_PAGES_MOST_VISITED_ENDPOINT_PATH = '/api/v1/tablist_pages/most_visited/'
export const TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH = '/api/v1/tablist_pages/recently_used/'
export const TABLIST_PAGES_SEARCH_ENDPOINT_PATH = '/api/v1/tablist_pages/recently_used/search/'
export const DIAGNOSTIC_LOGGING_ENDPOINT_PATH = '/api/v1/public/extension_log/'
export const ACTION_QUOTA_ENDPOINT_PATH = '/api/v1/action_quotas/current_user/'
export const PAYMENT_CHECKOUT_SESSION_ENDPOINT_PATH = '/api/v1/payment/checkout/'
export const PAYMENT_PORTAL_ENDPOINT_PATH = '/api/v1/payment/portal/'
export const SMART_SESSIONS_ENDPOINT_PATH = '/api/v1/smart_sessions/'
export const SMART_SESSION_ENDPOINT_PATH = (smart_session_pk: string) =>
  `/api/v1/smart_sessions/${smart_session_pk}/`
export const SMART_SESSION_PAGES_ENDPOINT_PATH = (smart_session_pk: string) =>
  `/api/v1/smart_sessions/${smart_session_pk}/pages/`
export const SMART_SESSIONS_ENDPOINT_PATH_V2 = '/api/v1/smart_sessions/v2/'
export const BOOTSTRAP_ENDPOINT_PATH = '/api/v1/bootstrap/'
export const AI_ACTION_LOGS_PATH = '/api/v1/ai_action_logs/'
export const AI_ACTION_LOGS_REVERT_PATH = (id: string) => `/api/v1/ai_action_logs/${id}/revert/`
export const OMNI_SEARCH_ENDPOINT_PATH = '/api/v1/search/'

export interface OmniSearchEndpointType {
  path: typeof OMNI_SEARCH_ENDPOINT_PATH
  GET: {
    description: 'Search for pages'
    queryParams: {
      query: string
      page: number
    }
    response: {
      results: TablistPageSearchResultType[]
      does_more_exist: boolean
    }
  }
}

export interface AIActionRevertEndpointType {
  path: ReturnType<typeof AI_ACTION_LOGS_REVERT_PATH>
  POST: {
    description: 'Revert an AI action'
    response: {
      ok: boolean
    }
  }
}

export interface AIActionEndpointType {
  path: typeof AI_ACTION_LOGS_PATH
  GET: {
    description: 'Get the user history'
    response: {
      count: number
      next: string
      previous: string
      results: AIActionLogType[]
    }
  }
}

export interface BootstrapEndpointType {
  path: typeof BOOTSTRAP_ENDPOINT_PATH
  POST: {
    description: 'Bootstrap with historical data'
    request: {
      historical_data: unknown
    }
    response: {
      ok: boolean
    }
  }
}

export interface UserResetEndpointType {
  path: ReturnType<typeof USER_RESET_ENDPOINT_PATH>
  POST: {
    description: 'Reset the user data'
    response: unknown
  }
}

export interface SmartSessionsEndpointType {
  path: typeof SMART_SESSIONS_ENDPOINT_PATH
  GET: {
    description: 'Get the user smart sessions'
    response: SmartSessionType[]
  }
  POST: {
    description: 'Get the user smart sessions given open tabs'
    request: {
      open_tabs: unknown[]
    }
    response: SmartSessionType[]
  }
}

export interface SmartSessionEndpointType {
  path: ReturnType<typeof SMART_SESSION_ENDPOINT_PATH>
  DELETE: {
    description: 'Delete a smart session'
    response: null
  }
}

export interface SmartSessionsEndpointV2Type {
  path: typeof SMART_SESSIONS_ENDPOINT_PATH_V2
  GET: {
    description: 'Get the user smart sessions'
    response: SmartSessionType[]
  }
  POST: {
    description: 'Get the user smart sessions given open tabs'
    request: {
      open_tabs: unknown[]
    }
    response: { sessions: SmartSessionType[]; do_more_pages_exist: boolean }
  }
}

export interface SmartSessionPagesEndpointType {
  path: ReturnType<typeof SMART_SESSION_PAGES_ENDPOINT_PATH>
  DELETE: {
    pathParams: [id: string]
    description: 'Delete a smart session page'
    response: null
  }
}

export interface PaymentPortalEndpointType {
  path: typeof PAYMENT_PORTAL_ENDPOINT_PATH
  POST: {
    description: 'Create a Stripe billing portal session'
    response: {
      ok: boolean
      url: string
    }
  }
}

export interface PaymentCheckoutSessionEndpointType {
  path: typeof PAYMENT_CHECKOUT_SESSION_ENDPOINT_PATH
  GET: {
    description: 'Get a Stripe checkout session status'
    response: {
      ok: boolean
      status: string
      payment_status: string
    }
  }
  POST: {
    description: 'Create a Stripe checkout session'
    queryParams: {
      is_annual: boolean
    }
    response: {
      ok: boolean
      client_secret: string
    }
  }
}

export interface ActionQuotaEndpointType {
  path: typeof ACTION_QUOTA_ENDPOINT_PATH
  GET: {
    description: 'Get the user action quota'
    response: ActionQuotaType
  }
}

export interface DiagnosticLoggingEndpointType {
  path: typeof DIAGNOSTIC_LOGGING_ENDPOINT_PATH
  POST: {
    description: 'Log diagnostic events'
    request: {
      events: {
        timestamp_ms: number
        message: string
      }[]
    }
    response: {
      ok: boolean
    }
  }
}

export interface ProjectsRecommendEndpointType {
  path: typeof PROJECTS_RECOMMEND_ENDPOINT_PATH
  POST: {
    description: 'Recommend projects'
    request: {
      title: string
      pages: TablistPageType[]
    }
    response: {
      titles?: string[]
      pages: TablistPageType[]
    }
  }
}

export interface ProjectsEndpointType {
  path: typeof PROJECTS_ENDPOINT_PATH
  GET: {
    description: `Get the user's projects`
    queryParams: {
      is_archived: boolean
    }
    response: ProjectType[]
  }
  POST: {
    description: `Create a new project`
    request: ProjectCreationType
    response: ProjectType
  }
}

export interface ProjectEndpointType {
  path: ReturnType<typeof PROJECT_ENDPOINT_PATH>
  GET: {
    description: `Get the user's project with matching id`
    response: ProjectType
  }
  PATCH: {
    description: `Partial update a user's project with matching id`
    request: Partial<
      Pick<ProjectType, 'title' | 'pages' | 'order' | 'is_archived'> & { is_deleted: boolean }
    >
    response: ProjectType
  }
}

export interface ProjectPermissionsEndpointType {
  path: ReturnType<typeof PROJECT_PERMISSIONS_ENDPOINT_PATH>
  GET: {
    description: `Get the permissions for a project`
    response: ProjectPermissionsType[]
  }
  POST: {
    description: `Set the permissions for a project`
    request: ProjectPermissionsType
    response: ProjectPermissionsType[]
  }
}

export interface ProjectPagesEndpointType {
  path: ReturnType<typeof PROJECT_PAGES_ENDPOINT_PATH>
  GET: {
    description: `Get the pages in a project`
    response: ProjectPageType[]
  }
  POST: {
    description: `Add a page to an existing project`
    request: {
      pages: ProjectPageCreationType[]
      index?: number
    }
    response: ProjectPageType[]
  }
  DELETE: {
    pathParams: [id: string]
    description: `Delete a page in a project`
    response: ProjectPageType
  }
  PUT: {
    pathParams: [id: string]
    description: `Update a page's properties in a project`
    request: Partial<Pick<ProjectPageType, 'title' | 'url'>> & {
      index?: number
      project_id?: string
      custom_title?: string
    }
    response: ProjectPageType
  }
}

export interface UpcomingPagesEndpointType {
  path: typeof UPCOMING_PAGE_ENDPOINT_PATH
  POST: {
    description: 'Get the upcoming pages for the current page'
    request: {
      url: string
      title: string
      pinned_urls: string[]
    }
    response: {
      upcoming_pages: UpcomingPageType[]
    }
  }
}

export interface MagicMomentEndpointType {
  path: typeof MAGIC_MOMENT_ENDPOINT_PATH
  POST: {
    request: {
      tablist: unknown[]
    }
  }
}

export interface AllSavedTabsEndpointType {
  path: typeof ALL_SAVED_TABS_ENDPOINT_PATH
  GET: {
    description: 'Get all saved tabs'
    queryParams: {
      page?: number
      should_include_most_visited?: boolean
      is_enhanced_tablist?: boolean
      should_include_user_closed?: boolean
    }
    response: {
      most_visited_pages?: TablistPageType[]
      tablist: TablistPageType[]
      groups: { [groupId: string]: TablistPageTopicGroupType }
      next_page: number | null
    }
  }
}

export interface TablistPagesEndpointType {
  path: typeof TABLIST_PAGES_ENDPOINT_PATH
  GET: {
    description: 'Get top tabs and putaside tabs in flat lists'
    queryParams: {
      should_include_most_visited?: boolean
      should_include_user_closed?: boolean
      to_ts?: number
    }
    response: {
      most_visited_pages?: TablistPageType[]
      tablist: TablistPageType[]
      do_more_saved_pages_exist: boolean
    }
  }
}

export interface TablistPagesMostVisitedEndpointType {
  path: typeof TABLIST_PAGES_MOST_VISITED_ENDPOINT_PATH
  DELETE: {
    description: 'Delete a most visited page'
    pathParams: [id: string]
    response: null
  }
}

export interface TablistPagesRecentlyUsedEndpointType {
  path: typeof TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH
  DELETE: {
    description: 'Delete a recently used page'
    pathParams: [id: string]
    response: null
  }
  POST: {
    description: 'Add recently used pages'
    request: {
      tablist: {
        tab_id: string
        url: string
        title: string
        favicon_url?: string
        last_access_time_ms: number
        window_id: number
      }[]
      is_manual: boolean
    }
    response: {
      ok: boolean
      details:
        | {
            data: TablistPageType[]
            remaining_quota: number
          }
        | { error: string }
    }
  }
}

export interface TablistPagesSearchEndpointType {
  path: typeof TABLIST_PAGES_SEARCH_ENDPOINT_PATH
  GET: {
    description: 'Search recently used pages'
    queryParams: {
      query: string
      to_ts: number // Unix timestamp in seconds
    }
    response: {
      results: TablistPageSearchResultType[]
    }
  }
}

export interface AnalyticsEndpointType {
  path: typeof ANALYTICS_ENDPOINT_PATH
  POST: {
    request: AnalyticsEventType
    response: {
      ok: boolean
    }
  }
}

export interface UserEventUpdateEndpointType {
  path: typeof USER_EVENT_UPDATE_ENDPOINT_PATH
  POST: {
    description: "Ingest an event to the user's historical data"
    request: UserEventUpdateType
    response: {
      ok: boolean
    }
  }
}

export interface UserInfoEndpointType {
  path: typeof USER_INFO_ENDPOINT_PATH
  PUT: {
    description: "Get the user's info"
    request: {
      iana_timezone?: string
      extension_version?: number
    }
    response: {
      ok: boolean
      data: UserInfoType
    }
  }
  DELETE: {
    description: 'Process uninstallation of the extension'
  }
}

export interface ExchangeGoogleAuthTokenEndpointType {
  path: typeof EXCHANGE_GOOGLE_AUTH_CODE_ENDPOINT_PATH
  POST: {
    description: "Exchange a Google auth code for a user's JWT token"
    // Request body is not JSON, but form data, so we can't easily use the type system to validate it
    // request: {
    //   code: string
    // }
    response: {
      key: string
    }
  }
}
