import { skeemaApi } from '../skeema'
import {
  ALL_SAVED_TABS_ENDPOINT_PATH,
  AllSavedTabsEndpointType,
  OMNI_SEARCH_ENDPOINT_PATH,
  OmniSearchEndpointType,
  TABLIST_PAGES_ENDPOINT_PATH,
  TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH,
  TABLIST_PAGES_SEARCH_ENDPOINT_PATH,
  TablistPagesEndpointType,
  TablistPagesRecentlyUsedEndpointType,
  TablistPagesSearchEndpointType,
} from '../../../models/endpoints.types'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { incInstantSaveQuota, setInstantSaveQuota } from '../../userSlice'
import { createSelector } from '@reduxjs/toolkit'
import { optimisticRemoveOpenTabs } from '../../../webapp/redux/extension'
import { deletePages, replacePages, savePages } from '../../tablistSlice'
import { removeBackendSearchResult } from '../../searchSlice'

export const tablistPagesApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    getTablistPages: builder.query<TablistPagesEndpointType['GET']['response'], undefined>({
      query: () => {
        return {
          url: `${TABLIST_PAGES_ENDPOINT_PATH}?should_include_most_visited=${true}&should_include_user_closed=${false}`,
          method: 'GET',
        }
      },
    }),
    getAllSavedTabs: builder.query<
      AllSavedTabsEndpointType['GET']['response'],
      AllSavedTabsEndpointType['GET']['queryParams']
    >({
      query: (params) => {
        const {
          page = 1,
          should_include_most_visited = true,
          is_enhanced_tablist = false,
          should_include_user_closed = false,
        } = params
        return {
          url: `${ALL_SAVED_TABS_ENDPOINT_PATH}?page=${page}&should_include_most_visited=${should_include_most_visited}&is_enhanced_tablist=${is_enhanced_tablist}&should_include_user_closed=${should_include_user_closed}`,
          method: 'GET',
        }
      },
    }),
    getOlderSavedPages: builder.query<
      TablistPagesEndpointType['GET']['response'],
      TablistPagesEndpointType['GET']['queryParams']
    >({
      query: (params) => {
        const {
          to_ts,
          should_include_most_visited = false,
          should_include_user_closed = false,
        } = params

        return {
          url: `${TABLIST_PAGES_ENDPOINT_PATH}?to_ts=${to_ts}&should_include_most_visited=${should_include_most_visited}&should_include_user_closed=${should_include_user_closed}`,
          method: 'GET',
        }
      },
    }),
    searchTablistPages: builder.query<
      TablistPagesSearchEndpointType['GET']['response'],
      TablistPagesSearchEndpointType['GET']['queryParams']
    >({
      query: (params) => {
        const { to_ts, query } = params

        return {
          url: `${TABLIST_PAGES_SEARCH_ENDPOINT_PATH}?query=${query}&to_ts=${to_ts}`,
          method: 'GET',
        }
      },
    }),
    omnisearch: builder.query<
      OmniSearchEndpointType['GET']['response'],
      OmniSearchEndpointType['GET']['queryParams']
    >({
      query: (params) => {
        const { query, page } = params
        return {
          url: `${OMNI_SEARCH_ENDPOINT_PATH}?query=${query}&page=${page}`,
          method: 'GET',
        }
      },
    }),
    deleteTablistPage: builder.mutation<
      TablistPagesRecentlyUsedEndpointType['DELETE']['response'],
      { page: TablistPageType; index: number }
    >({
      query: ({ page }) => {
        const { id } = page
        return {
          url: `${TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH}${id}/`,
          method: 'DELETE',
        }
      },
      onQueryStarted: async ({ page, index }, { dispatch, queryFulfilled }) => {
        const { id } = page
        const undoPayload = { index, pages: [page] }

        dispatch(deletePages([id]))
        dispatch(removeBackendSearchResult(id))
        const response = dispatch(
          tablistPagesApi.util.updateQueryData(
            'getTablistPages',
            undefined,
            (draft: TablistPagesEndpointType['GET']['response']) => {
              const idx = draft.tablist.findIndex((page) => page.id === id)
              if (idx !== -1) {
                draft.tablist.splice(idx, 1)
              }
            },
          ),
        )

        await queryFulfilled.catch(() => {
          response.undo()
          if (undoPayload) {
            dispatch(savePages(undoPayload))
          }
        })
      },
    }),
    appendTablistPages: builder.mutation<
      TablistPagesRecentlyUsedEndpointType['POST']['response'],
      { pages: TablistPageType[]; isManual: boolean }
    >({
      query: ({ pages, isManual }) => {
        const tabs = pages.map((page) => ({
          tab_id: page.id,
          url: page.url,
          title: page.title,
          favicon_url: page.favicon_url,
          last_access_time_ms: page.last_access_timestamp_ms,
          window_id: page.window_id ?? -1,
        }))
        const data: TablistPagesRecentlyUsedEndpointType['POST']['request'] = {
          tablist: tabs,
          is_manual: isManual,
        }
        const additionalTimeout = (tabs.length / 25) * 1000

        return {
          url: `${TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH}`,
          method: 'POST',
          body: JSON.stringify(data),
          timeout: 10000 + additionalTimeout,
        }
      },
      onQueryStarted: async ({ pages, isManual }, { dispatch, queryFulfilled }) => {
        const newPages: TablistPageType[] = pages.map((page) => ({
          ...page,
          entity_type: TABLIST_PAGE_ENUM.RECENTLY_USED,
          is_pinned: false,
          is_manual: isManual,
        }))

        const optimisticRemoveOpenTabResult = optimisticRemoveOpenTabs(
          dispatch,
          newPages.map((page) => page.id),
        )
        const optimisticTablistUpdate = dispatch(
          tablistPagesApi.util.updateQueryData(
            'getTablistPages',
            undefined,
            (draft: TablistPagesEndpointType['GET']['response']) => {
              draft.tablist.unshift(...newPages)
            },
          ),
        )
        dispatch(savePages({ pages: newPages }))
        // dispatch(decInstantSaveQuota(undefined))
        try {
          const { data: response } = await queryFulfilled

          if (!response.ok) {
            const details = response.details as { error: string }
            if (details.error === 'QUOTA_EXCEEDED') {
              optimisticRemoveOpenTabResult.undo()
              optimisticTablistUpdate.undo()
              dispatch(deletePages(newPages.map((page) => page.id)))
              // dispatch(setInstantSaveQuota(0))
              return
            }
            throw new Error(details.error)
          }

          const { data: upsertedEntities, remaining_quota } = response.details as {
            data: TablistPageType[]
            remaining_quota: number
          }
          dispatch(
            tablistPagesApi.util.updateQueryData(
              'getTablistPages',
              undefined,
              (draft: TablistPagesEndpointType['GET']['response']) => {
                for (let i = 0; i < upsertedEntities.length; i++) {
                  const id = upsertedEntities[i].id
                  const newPage = upsertedEntities[i]
                  const idx = draft.tablist.findIndex((p) => p.id === id)
                  if (idx !== -1) {
                    draft.tablist.splice(idx, 1, newPage)
                  }
                }
              },
            ),
          )
          dispatch(
            replacePages({ ids: newPages.map((page) => page.id), newPages: upsertedEntities }),
          )
          dispatch(setInstantSaveQuota(remaining_quota))
        } catch (e) {
          optimisticRemoveOpenTabResult.undo()
          optimisticTablistUpdate.undo()
          dispatch(deletePages(newPages.map((page) => page.id)))
          dispatch(incInstantSaveQuota(undefined))
        }
      },
    }),
  }),
})

const selectGetTablistPagesQueryResult = tablistPagesApi.endpoints.getTablistPages.select(undefined)
export const selectMostVisitedPages = createSelector(selectGetTablistPagesQueryResult, (result) => {
  return result.data?.most_visited_pages
})
export const selectPutasidePages = createSelector(selectGetTablistPagesQueryResult, (result) => {
  return result.data?.tablist
})

export const {
  useGetAllSavedTabsQuery,
  useLazyGetAllSavedTabsQuery,
  useGetTablistPagesQuery,
  useLazyGetTablistPagesQuery,
  useLazyGetOlderSavedPagesQuery,
  useLazySearchTablistPagesQuery,
  useLazyOmnisearchQuery,
  useDeleteTablistPageMutation,
  useAppendTablistPagesMutation,
} = tablistPagesApi

export type LazySearchTablistPagesType = ReturnType<typeof useLazySearchTablistPagesQuery>
export type LazyOmniSearchType = ReturnType<typeof useLazyOmnisearchQuery>
