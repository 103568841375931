import React, { FC, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { AiOutlineChrome } from 'react-icons/ai'
import { TiPin, TiPinOutline } from 'react-icons/ti'
import { Box, Checkbox, Flex, Icon, Tooltip, Text } from '@chakra-ui/react'
import {
  MdCheck,
  MdClose,
  MdKeyboardReturn,
  MdOutlineDriveFileMove,
  MdOutlineFolder,
} from 'react-icons/md'
import { getLocalDateTimeString, getRelativeTimeString } from '../../../utils/utils'
import { FiDownload, FiEdit2 } from 'react-icons/fi'
import { cx } from '@emotion/css'
import DragIcon from '../../../components/DragIcon'
import { TABLIST_PAGE_ENUM } from '../../../models/tablist_pages.types'
import { MdOutlineInbox } from 'react-icons/md'
import { MdOutlineViewAgenda } from 'react-icons/md'
import { FaCircle } from 'react-icons/fa'

export const HIGHLIGHT_ANIMATION_DURATION_MS = 3800 //keeps in sync with the 3000ms toast duration
const highlightAnimation = keyframes`
  0% {
    background: transparent;
  }
  30% {
    background: rgba(0, 113, 227, 0.2);
  }
  70% {
    background: rgba(0, 113, 227, 0.2);
  }
  100% {
    background: transparent;
  }
`

export enum PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM {
  DEFAULT = 'default',
  SAVED_FOR_LATER = 'saved_for_later',
  FOLDER_CREATION = 'folder_creation',
  TOPIC_GROUP = 'topic_group',
}

const TabBodyContainer = styled.div<{
  $isFocused: boolean
  $isDraggingThis: boolean
  $isDraggingDisabled: boolean
  $isTitleClickDisabled: boolean
  $isSelected: boolean
  $isTabAboveSelected: boolean
  $isTabBelowSelected: boolean
  $isCheckboxShown: boolean
  $isHoverDisabled: boolean
  $isGrayedOut: boolean
  $isHighlighted: boolean
  $styleVariant: PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM
  $isDragPreview: boolean
  $shouldDisablePointerEvents: boolean
}>`
  position: relative;
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;
  flex-shrink: 0;

  border-radius: ${({ $isHighlighted }) => ($isHighlighted ? '0px' : '6px')};
  border: 1px solid transparent;
  opacity: 1;
  z-index: ${({ $isFocused }) => ($isFocused ? 11 : 10)};

  ${({ $styleVariant }) =>
    $styleVariant !== PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.FOLDER_CREATION &&
    css`
      /* Not sure why this was originally added, but it needed to be disabled for the dnd indicator line used in folder creation */
      overflow: hidden;
    `}

  opacity: ${({ $isDraggingThis, $isGrayedOut }) => ($isDraggingThis || $isGrayedOut ? 0.5 : 1)};

  ${({ $isDragPreview }) =>
    $isDragPreview &&
    css`
      background: #ebebeb;
      max-width: 300px;
      border: 1px solid #d5d5d5;
    `};

  & .hover-show:not(.is-focused) {
    opacity: 0;
    transition:
      width 0.2s,
      opacity 0.2s;
  }

  ${({
    $isHoverDisabled,
    $isFocused,
    $isDraggingDisabled,
    $isDraggingThis,
    $isGrayedOut,
    $isSelected,
  }) =>
    !$isHoverDisabled &&
    css`
      &:hover,
      &.forceHoverStates {
        ${!$isDraggingThis && $isGrayedOut
          ? css`
              opacity: 1;
            `
          : ''};
        background: ${$isFocused ? 'rgba(0, 113, 227, 0.2)' : $isSelected ? '#cce3f9' : '#EBEBEB'};
        border-color: ${!$isSelected ? '#d5d5d5' : 'transparent'};

        .hover-show {
          opacity: 1;
        }

        .DragIcon {
          opacity: ${$isDraggingDisabled ? 0 : 1};
          cursor: ${$isDraggingThis ? 'grabbing' : $isDraggingDisabled ? 'auto' : 'grab'}!important;
        }
      }
    `}

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      outline: 1px solid #0071e3;
      background: rgba(0, 113, 227, 0.2);
    `}

  ${({ $isSelected, $isTabAboveSelected, $isTabBelowSelected, $isCheckboxShown }) =>
    $isSelected &&
    !$isCheckboxShown &&
    css`
      background: #cce3f9;
      border-radius: ${$isTabAboveSelected && $isTabBelowSelected
        ? '0px'
        : $isTabAboveSelected
          ? '0 0 6px 6px'
          : $isTabBelowSelected
            ? '6px 6px 0 0'
            : '6px'};
    `}

  /* Wrapping elements in spans to create larger click targets */
  & > span {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .TabBodyContainerIcon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    opacity: 1;
    cursor: pointer;

    & > span {
      position: relative; //Need for children showOnHover/hideOnHover to be properly centered
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  .TabBodyContainerTitle {
    flex-grow: 2;
    min-width: 0;
    margin-right: 16px;

    & > a {
      max-width: 90%;
    }

    p.Title {
      cursor: ${({ $isTitleClickDisabled }) => ($isTitleClickDisabled ? 'default' : 'pointer')};
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #585858;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */

      & > mark {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-decoration: inherit;
        padding: 0;
        background-color: #585858;
        color: white;
      }

      &:hover:not(.disabled) {
        text-decoration: underline;
      }
    }
  }

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      animation: ${highlightAnimation} ${HIGHLIGHT_ANIMATION_DURATION_MS}ms
        cubic-bezier(0.4, 0, 0.2, 1) forwards;
      .TabBodyContainerTitle {
        p.Title {
          color: #000;
        }
      }
    `}

  .TabBodyContainerProperty {
    flex-shrink: 0;

    ${({ $styleVariant }) =>
      $styleVariant === PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.SAVED_FOR_LATER
        ? css`
            min-width: 113px;
            width: 113px;
          `
        : $styleVariant === PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.TOPIC_GROUP
          ? css`
              min-width: 97px;
              width: 97px;
            `
          : $styleVariant === PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.FOLDER_CREATION
            ? css`
                min-width: 54px;
                width: 54px;
              `
            : css`
                min-width: 140px;
                width: 140px;
              `}

    overflow: hidden;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #a7a7a7;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }

  .TabBodyContainerActionIcons,
  .TabBodyContainerFolderCreationIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${({ $styleVariant }) =>
      $styleVariant === PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.SAVED_FOR_LATER ||
      $styleVariant === PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.TOPIC_GROUP
        ? css`
            flex-shrink: 0;
          `
        : css`
            min-width: 84px;
          `}
  }

  .TabBodyContainerFolderCreationIcons {
    ${({ $styleVariant }) =>
      $styleVariant === PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.SAVED_FOR_LATER
        ? css`
            position: relative;
            right: 25px;
          `
        : ''}
  }

  .force-show {
    opacity: 1 !important;
  }

  ${({ $shouldDisablePointerEvents }) =>
    $shouldDisablePointerEvents &&
    css`
      pointer-events: none;
    `}
`

const ReturnIconContainer = styled.div`
  width: 24px;
  height: 16px;
  padding: 2px 4px;
  margin-right: 6px;
  border-radius: 4px;
  background: #0071e3;

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100%;
    height: 100%;
    color: white;
  }
`

const DndIndicatorLine = styled.div<{ $isVisible: boolean; $isTop: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  position: absolute;
  width: calc(100% - 26px);
  height: 2px;
  top: ${({ $isTop }) => ($isTop ? '-2px' : '26px')};
  right: 12px;
  background: #0071e3;
  pointer-events: none;
  z-index: 11;
`

function highlightText(text: string, indexMatches?: [number, number][]): ReactNode[] {
  if (!indexMatches || indexMatches.length === 0) {
    return [<React.Fragment key={1}>{text}</React.Fragment>]
  }

  const indices = [...indexMatches].sort((a, b) => a[0] - b[0])
  let lastEnd = 0
  const result: ReactNode[] = []

  for (let i = 0; i < indices.length; i++) {
    const start = indices[i][0]
    const end = Math.min(indices[i][1] + 1, text.length)

    result.push(text.slice(lastEnd, start))

    const marked = <mark key={i}>{text.slice(start, end)}</mark>
    result.push(marked)

    lastEnd = end
  }

  result.push(text.slice(lastEnd))

  return result
}

const getLocationIcon = (entityType: TABLIST_PAGE_ENUM) => {
  switch (entityType) {
    case TABLIST_PAGE_ENUM.OPEN:
      return <Icon as={FaCircle} boxSize={2} color="green" ml={1} mr={2} />
    case TABLIST_PAGE_ENUM.RECENTLY_USED:
      return <Icon as={MdOutlineInbox} mr={1} color="#A7A7A7" />
    case TABLIST_PAGE_ENUM.PROJECT_PAGE:
      return <Icon as={MdOutlineFolder} mr={1} color="#A7A7A7" />
    case TABLIST_PAGE_ENUM.SMART_SESSION:
      return <Icon as={MdOutlineViewAgenda} mr={1} color="#A7A7A7" />
  }
}

interface PropTypes {
  title: string
  location?: string
  entityType: TABLIST_PAGE_ENUM
  favIconUrl?: string
  lastAccessDateTime?: string
  handleClickFavIcon: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickDragIcon: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickDeleteIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickMoveIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickSaveIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickTitle: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickCheckbox?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickBody: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  handleClickEditIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  connectDnD?: (node: HTMLDivElement | null) => void
  titleHighlights?: [number, number][]
  isDraggingThis: boolean
  isDraggingDisabled: boolean
  isSelected: boolean
  isTabAboveSelected: boolean
  isTabBelowSelected: boolean
  isCheckboxShown: boolean
  isSelectionDisabled: boolean
  isHoverDisabled: boolean
  isSelectionDisabledTooltipLabel?: string
  isTitleClickDisabled: boolean
  isTitleEditable?: boolean
  showTimeString: boolean
  showActionIcons: boolean
  isFocused: boolean
  styleVariant: PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM
  isDragIconPlaceholderHidden: boolean
  shouldShowDeleteIcon: boolean
  isHighlighted?: boolean
  showOpenTabIcon?: boolean
  isPinnedTab?: boolean
  titleToolTip?: string | ReactNode
  deleteIconToolTip?: string
  saveIconTooltip?: string
  moveIconTooltip?: string
  shouldShowPinIconForCheckbox?: boolean
  shouldDisplayLocation?: boolean
  shouldHighlightTop?: boolean
  shouldHighlightBottom?: boolean
  isDragPreview?: boolean
  shouldDisablePointerEvents?: boolean
  showAiSuggestion?: boolean
  aiSuggestionReason?: string
  shouldShowFolderCreationAddIcon?: boolean
  handleClickFolderCreationAddIcon?: () => void
  isPendingInFolderCreation?: boolean
}

const PutasideTabView: FC<PropTypes> = (props) => {
  const {
    title,
    location,
    entityType,
    favIconUrl,
    lastAccessDateTime,
    handleClickFavIcon,
    handleClickDragIcon,
    handleClickDeleteIcon,
    handleClickSaveIcon,
    handleClickTitle,
    handleClickEditIcon,
    handleClickCheckbox,
    handleClickBody,
    connectDnD,
    isDraggingThis,
    isDraggingDisabled,
    isSelected,
    isTabAboveSelected,
    isTabBelowSelected,
    isCheckboxShown,
    isSelectionDisabled,
    isHoverDisabled,
    isSelectionDisabledTooltipLabel,
    isTitleClickDisabled,
    isTitleEditable,
    showTimeString,
    showActionIcons,
    titleHighlights,
    isFocused,
    styleVariant,
    showOpenTabIcon,
    isPinnedTab,
    titleToolTip,
    deleteIconToolTip,
    saveIconTooltip,
    shouldShowPinIconForCheckbox,
    shouldDisplayLocation,
    handleClickMoveIcon,
    moveIconTooltip,
    shouldHighlightTop,
    shouldHighlightBottom,
    isDragIconPlaceholderHidden,
    isHighlighted,
    isDragPreview,
    shouldDisablePointerEvents,
    showAiSuggestion,
    aiSuggestionReason,
    shouldShowFolderCreationAddIcon,
    handleClickFolderCreationAddIcon,
    isPendingInFolderCreation,
    shouldShowDeleteIcon,
  } = props

  return (
    <TabBodyContainer
      className="TabBodyContainer"
      ref={(node) => {
        connectDnD && connectDnD(node)
      }}
      $isFocused={isFocused}
      $isDraggingThis={isDraggingThis}
      $isDraggingDisabled={isDraggingDisabled}
      $isTitleClickDisabled={isTitleClickDisabled}
      $isHoverDisabled={isHoverDisabled}
      $isGrayedOut={!!shouldShowPinIconForCheckbox && !isSelected}
      $isSelected={isSelected}
      $isCheckboxShown={isCheckboxShown}
      $isTabAboveSelected={isTabAboveSelected}
      $isTabBelowSelected={isTabBelowSelected}
      $styleVariant={styleVariant}
      $isHighlighted={!!isHighlighted}
      $isDragPreview={!!isDragPreview}
      $shouldDisablePointerEvents={!!shouldDisablePointerEvents}
      onClick={handleClickBody}
    >
      <DndIndicatorLine
        $isVisible={!!shouldHighlightTop || !!shouldHighlightBottom}
        $isTop={!!shouldHighlightTop}
      />
      {!isDragIconPlaceholderHidden && (
        <Flex
          as="span"
          className={cx('DragIcon', 'hover-show', {
            'is-focused': isFocused,
            'force-show': isDragPreview,
          })}
          alignItems="center"
          justifyContent="center"
          width={{ base: '8px', md: '16px' }}
          marginRight="-4px"
          flexShrink={0}
          overflow="hidden"
          onClick={handleClickDragIcon}
        >
          <DragIcon />
        </Flex>
      )}
      {isCheckboxShown && handleClickCheckbox && (
        <Tooltip
          placement="top"
          isDisabled={!isSelectionDisabled || !isSelectionDisabledTooltipLabel}
          label={isSelectionDisabledTooltipLabel}
          bg="#585858"
        >
          <Box
            className={cx('TabBodyContainerIcon', { clickable: !isSelectionDisabled })}
            mr="4px"
            onClick={handleClickCheckbox}
          >
            {!shouldShowPinIconForCheckbox && (
              <Checkbox
                colorScheme="black"
                size="md"
                isChecked={isSelected}
                isDisabled={isSelectionDisabled}
                isReadOnly
                pointerEvents="none"
              />
            )}
            {shouldShowPinIconForCheckbox && (
              <>
                {isSelected && <TiPin strokeWidth={'2px'} color="#000" />}
                {!isSelected && <TiPinOutline color="#000" />}
              </>
            )}
          </Box>
        </Tooltip>
      )}

      <span className="TabBodyContainerIcon" onClick={handleClickFavIcon}>
        {favIconUrl ? (
          <img src={favIconUrl} width="16" height="16" />
        ) : (
          <AiOutlineChrome style={{ width: '16px', height: '16px', color: 'black' }} />
        )}
      </span>

      <span className="TabBodyContainerTitle">
        <Tooltip
          openDelay={300}
          placement="top"
          label={titleToolTip}
          isDisabled={isTitleClickDisabled}
        >
          <p
            className={cx('Title', { disabled: isTitleClickDisabled })}
            onClick={isTitleClickDisabled ? undefined : handleClickTitle}
          >
            {highlightText(title, titleHighlights)}
          </p>
        </Tooltip>

        {isTitleEditable && (
          <Tooltip placement="top" label="Rename title">
            <span
              className="TabBodyContainerIcon hover-show clickable"
              onClick={handleClickEditIcon}
            >
              <FiEdit2 color="#585858" size={16} style={{ marginLeft: '5px', flexShrink: 0 }} />
            </span>
          </Tooltip>
        )}

        {isPinnedTab && (
          <TiPin color="#A7A7A7" size={16} style={{ marginLeft: '5px', flexShrink: 0 }} />
        )}
        {!isPinnedTab && showOpenTabIcon && (
          <span
            title="This tab is open"
            style={{
              marginLeft: '8px',
              flexShrink: 0,
              width: '8px',
              height: '8px',
              borderRadius: '2px',
              backgroundColor: '#0c894e',
            }}
          />
        )}
      </span>

      {showAiSuggestion && (
        <Box
          bg="rgba(12, 137, 78, 0.2)"
          color="#0C894E"
          borderRadius="16px"
          h="16px"
          minW="290px"
          p="2px 6px"
          mr="8px"
          display="flex"
          alignItems="center"
        >
          <Text fontSize="12px" fontWeight={500} mr="4px">
            {aiSuggestionReason}
          </Text>
          <Icon as={MdClose} color="#0C894E" />
          <Icon as={MdCheck} color="#0C894E" />
        </Box>
      )}

      {shouldDisplayLocation && (
        <span className="TabBodyContainerProperty">
          {getLocationIcon(entityType)}
          <p className="Location">{location}</p>
        </span>
      )}

      {showTimeString && (
        <span className="TabBodyContainerProperty">
          {lastAccessDateTime && lastAccessDateTime.trim() !== '' && (
            <p className="Time" title={getLocalDateTimeString(lastAccessDateTime)}>
              {getRelativeTimeString(lastAccessDateTime)}
            </p>
          )}
        </span>
      )}

      {showActionIcons && (
        <span className="TabBodyContainerActionIcons">
          {!shouldShowDeleteIcon && (
            <span style={{ width: '32px', cursor: 'unset' }} className="TabBodyContainerIcon" />
          )}

          {shouldShowDeleteIcon && (
            <Tooltip placement="top" label={deleteIconToolTip}>
              <span
                style={{
                  width: '24px',
                  marginRight: handleClickSaveIcon || handleClickMoveIcon ? '0' : '4px',
                }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickDeleteIcon}
              >
                <MdClose
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {handleClickSaveIcon && (
            <Tooltip placement="top" label={saveIconTooltip}>
              <span
                style={{ width: '24px', marginRight: handleClickMoveIcon ? '0' : '4px' }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickSaveIcon}
              >
                <FiDownload
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {handleClickMoveIcon && (
            <Tooltip placement="top" label={moveIconTooltip}>
              <span
                style={{ width: '24px', marginRight: '4px' }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickMoveIcon}
              >
                <MdOutlineDriveFileMove
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {isFocused && (
            <Tooltip placement="top" label={'Press Enter to open'}>
              <ReturnIconContainer>
                <MdKeyboardReturn />
              </ReturnIconContainer>
            </Tooltip>
          )}
        </span>
      )}

      {shouldShowFolderCreationAddIcon && (
        <span className="TabBodyContainerFolderCreationIcons">
          {!isPendingInFolderCreation && (
            <Tooltip placement="top" label={'Add to folder'}>
              <Flex
                bg="#CCE3F9"
                border={isSelected ? '1px solid #0071e377' : 'none'}
                borderRadius="6px"
                color="#0071E3"
                w="51px"
                h="22px"
                flexShrink={0}
                alignItems="center"
                justifyContent="center"
                onClick={handleClickFolderCreationAddIcon}
                cursor="pointer"
                mr="2px"
              >
                <Text fontSize="12px" fontWeight="500">
                  + Add
                </Text>
              </Flex>
            </Tooltip>
          )}
          {isPendingInFolderCreation && (
            <Tooltip placement="top" label={'Added'}>
              <Flex w="53px" alignItems="center" justifyContent="flex-end">
                <Icon
                  as={MdCheck}
                  bg="#CCE3F9"
                  borderRadius="6px"
                  color="#0071E3"
                  w="22px"
                  h="22px"
                  p="4px"
                  mr="2px"
                  flexShrink={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                />
              </Flex>
            </Tooltip>
          )}
        </span>
      )}
    </TabBodyContainer>
  )
}

export default PutasideTabView
