import React, { FC, useMemo } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { Box, UnorderedList, ListItem, Text, Spinner } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import { getS } from '../../../utils/utils'

const Container = styled(Box)`
  * {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: #000;
    letter-spacing: -1px;

    @media (max-width: 1200px) {
      /* Screens narrower than 1200px */
      font-size: 24px;
      line-height: 36px;
    }
  }
`

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingStatsDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props

  const { data: tabs, isLoading } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  const [numTabs, numTabGroups, numWindows, numUnusedTabs] = useMemo(() => {
    if (!tabs) {
      return [undefined, undefined, undefined, undefined]
    }

    const numTabs = tabs.length
    const numTabGroups = new Set(tabs.map((t) => t.group_id)).size - 1
    const numWindows = new Set(tabs.map((t) => t.window_id)).size
    const numUnusedTabs = tabs.filter(
      (t) => t.last_access_timestamp_ms < Date.now() - 24 * 60 * 60 * 1000,
    ).length

    return [numTabs, numTabGroups, numWindows, numUnusedTabs]
  }, [tabs])

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      phaseText={`1. DECLUTTERING`}
      bodyContent={
        <>
          {isLoading && (
            <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
              <Spinner color="blue.500" size="lg" speed="1s" />
            </div>
          )}
          {!isLoading && (
            <Container>
              <Text mb="4px">Right now, you have:</Text>
              <UnorderedList mb="16px" marginInlineStart="1.5em">
                <ListItem>
                  {numTabs} open tab{getS(numTabs)}
                </ListItem>
                {(numTabGroups ?? 0) > 0 && (
                  <ListItem>
                    {numTabGroups} tab group{getS(numTabGroups)}
                  </ListItem>
                )}
                {(numWindows ?? 0) > 0 && (
                  <ListItem>
                    {numWindows} window{getS(numWindows)}
                  </ListItem>
                )}
              </UnorderedList>
              {(numUnusedTabs ?? 0) > 0 && (
                <Text>{`${numUnusedTabs} tab${
                  numUnusedTabs === 1 ? ` hasn't` : `s haven't`
                } been used in the last 24 hours.`}</Text>
              )}
            </Container>
          )}
        </>
      }
      buttonText={`Next`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingStatsDialog
