import React, { FC, useRef, useEffect } from 'react'
import { Flex, Box, Grid, GridItem } from '@chakra-ui/react'
import { css } from '@emotion/css'
import ProjectsSidebar from './projectsSidebar/ProjectsSidebar'
import SubNavButtons from './SubNavButtons'
import PaymentFlowModal from './payment/PaymentFlowModal'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import CustomDragLayer from './CustomDragLayer'
import SearchBar from './search/SearchBar'
import PinningTutorialOverlay from './onboarding/PinningTutorialOverlay'
import ProjectArchiveDashboard from './projectArchive/ProjectArchiveDashboard'
import { useReduxDispatch, useReduxSelector } from '../../redux/baseStore'
import PutasideTabList, { PUTASIDE_TABLIST_VIEW_ENUM } from './PutasideTabList/PutasideTabList'
import PutasideTabListV2 from './PutasideTabListV2/PutasideTabList'
import {
  ONBOARDING_TUTORIAL_STEPS,
  VALID_ONBOARDING_TUTORIAL_STEPS,
} from './onboarding/onboarding.types'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../models/browserStorage.types'
import MagicMomentTablist from './onboarding/MagicMomentTablist'
import PinningTablist from './onboarding/PinningTablist'
import OpenTablist from './onboarding/OpenTablist'
import ProjectDetailDashboard from './projectDetail/ProjectDetailDashboard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CompletedActionsDashboard from './completedActions/CompletedActionsDashboard'
import { useLocalStorage } from '../../utils/genericHooks'
import OnboardingFlowController from './onboarding/OnboardingFlowController'
import SettingsDashboard from './settings/SettingsDashboard'
import GuidedAutoSaveModal from './guidedAutoSave/GuidedAutoSaveModal'
import FixAutoSaveTimingBugWidget from './settings/FixAutoSaveTimingBugWidget'
import {
  activateFolderCreationMode,
  selectIsFolderCreationModeActive,
} from '../../redux/projectsSlice'
import SpotlightWrapper from './onboarding/SpotlightWrapper'

export const SIDEBAR_TRANSITION_DURATION_MS = 400

export type CONTENT_TYPE =
  | 'tablist'
  | 'archive'
  | 'project'
  | 'saved'
  | 'actions'
  | 'settings'
  | 'sessions'

const disableScrollClassName = css`
  overflow: hidden;
  max-width: 100vw;
`

interface Props {
  contentType: CONTENT_TYPE
}

const MainDashboard: FC<Props> = (props) => {
  const { contentType } = props
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useReduxDispatch()
  const { projectId } = useParams<{ projectId: string }>()
  const { hash } = useLocation()
  const isGuidedAutoSaveModalOpen = hash === '#guided_autosave'
  const authHeader = useReduxSelector((state) => state.user.authHeader)
  const isAuthLoading = useReduxSelector((state) => state.user.isAuthLoading)
  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)

  const tablistScrollRef = useRef<HTMLDivElement | null>(null)
  const centralPanelRef = useRef<HTMLDivElement | null>(null)
  const { featureFlags } = useFeatureFlagContext()
  const isPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]
  const isSimplifiedASTEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsSimplifiedASTEnabled]
  const [currentStep, setCurrentStep] = useLocalStorage<ONBOARDING_TUTORIAL_STEPS>(
    WEBAPP_LOCAL_STORAGE_KEYS.CurrentOnboardingTutorialStepV2,
    null,
  )
  const isOnboardingTutorialEnabled =
    currentStep !== null &&
    currentStep !== ONBOARDING_TUTORIAL_STEPS.DONE &&
    VALID_ONBOARDING_TUTORIAL_STEPS[currentStep]

  const isOnboardingConclusionStep = currentStep === ONBOARDING_TUTORIAL_STEPS.Conclusion

  useEffect(() => {
    if (
      isOnboardingTutorialEnabled &&
      !isOnboardingConclusionStep &&
      !['/', '/saved', '/sessions'].includes(location.pathname)
    ) {
      navigate('/')
    }
  }, [isOnboardingTutorialEnabled, isOnboardingConclusionStep, location.pathname, navigate])

  const shouldBlurFullScreen =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.Preface

  const shouldDisableScroll = shouldBlurFullScreen

  const isProjectButtonOnboardingActive =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.NewFolderButton

  const isProjectCreatedOnboardingActive =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.FolderCreated

  const isFolderCreationOnboardingActive =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.FolderCreation
  const isFolderCreationOnboardingActiveRef = useRef(isFolderCreationOnboardingActive)

  useEffect(() => {
    isFolderCreationOnboardingActiveRef.current = isFolderCreationOnboardingActive
  }, [isFolderCreationOnboardingActive])

  useEffect(() => {
    //This makes sure that the folder creation mode is activated when refreshing on the current step
    //TODO: Fix this...it's a hacky solution, but it works for now
    if (!isFolderCreationOnboardingActiveRef.current) {
      return
    }
    setTimeout(() => {
      if (isFolderCreationOnboardingActiveRef.current) {
        dispatch(activateFolderCreationMode({}))
      }
    }, 500)
  }, [isFolderCreationOnboardingActive, isFolderCreationModeActive, dispatch])

  const projectSidebarWidth = isFolderCreationModeActive
    ? isFolderCreationOnboardingActive
      ? '75%'
      : '50%'
    : isProjectButtonOnboardingActive
      ? '352px'
      : '320px'
  const projectSidebarMinWidth = isFolderCreationModeActive
    ? isFolderCreationOnboardingActive
      ? '548px'
      : '450px'
    : isProjectButtonOnboardingActive
      ? '352px'
      : '320px'
  const projectSidebarMaxWidth = isFolderCreationModeActive
    ? isFolderCreationOnboardingActive
      ? '700px'
      : '600px'
    : isProjectButtonOnboardingActive
      ? '352px'
      : '320px'

  const centralPanelMinWidth = isFolderCreationModeActive ? '350px' : '552px'

  const isTablistEnabled =
    !isOnboardingTutorialEnabled ||
    (currentStep !== ONBOARDING_TUTORIAL_STEPS.Preface &&
      currentStep !== ONBOARDING_TUTORIAL_STEPS.Intro)

  const isMagicMomentTablistEnabled =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig

  const isPinningTablistEnabled =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.PinTabs

  const isOpenTablistEnabled =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.Stats

  const isPutasideTablistEnabled =
    !isMagicMomentTablistEnabled && !isPinningTablistEnabled && !isOpenTablistEnabled

  const isSavedTabsOnboardingStepActive =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.TabList

  const shouldDisableTablistScroll = false

  useEffect(() => {
    //This boolean exists so this useEffect runs every time the tablist changes
    const isAnyTablistEnabled =
      isTablistEnabled ||
      isMagicMomentTablistEnabled ||
      isPinningTablistEnabled ||
      isOpenTablistEnabled ||
      isPutasideTablistEnabled
    if (tablistScrollRef.current && isAnyTablistEnabled) {
      tablistScrollRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [
    isMagicMomentTablistEnabled,
    isOpenTablistEnabled,
    isPinningTablistEnabled,
    isPutasideTablistEnabled,
    isTablistEnabled,
  ])

  if (isAuthLoading || !authHeader) {
    return null
  }

  return (
    <Flex
      className={shouldDisableScroll ? disableScrollClassName : ''}
      direction="row"
      h="100%"
      w="100%"
    >
      <FixAutoSaveTimingBugWidget />
      {isGuidedAutoSaveModalOpen && <GuidedAutoSaveModal />}
      <PinningTutorialOverlay />
      <CustomDragLayer />
      {isPaywallEnabled && <PaymentFlowModal />}
      <OnboardingFlowController
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
      />
      <Box
        width={projectSidebarWidth}
        minWidth={projectSidebarMinWidth}
        maxWidth={projectSidebarMaxWidth}
        transition={`all ${SIDEBAR_TRANSITION_DURATION_MS}ms ease-in-out`}
        h="100%"
        overflow={isFolderCreationModeActive ? undefined : 'hidden'}
      >
        <ProjectsSidebar
          detailProjectId={projectId}
          isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
          isProjectButtonOnboardingActive={isProjectButtonOnboardingActive}
          isFolderCreationOnboardingActive={isFolderCreationOnboardingActive}
          isProjectCreatedOnboardingActive={isProjectCreatedOnboardingActive}
        />
      </Box>
      <Grid
        templateColumns="repeat(12, 1fr)"
        templateRows="68px auto"
        flex="1 1 auto"
        p="24px 24px 0 24px"
      >
        <GridItem
          colSpan={8}
          gridRow={1}
          minWidth={centralPanelMinWidth}
          maxWidth="1000px"
          opacity={isOnboardingTutorialEnabled ? 0.4 : 1}
        >
          <SearchBar />
        </GridItem>

        <GridItem
          colSpan={4}
          gridColumnStart={9}
          gridRow={1}
          minWidth="154px"
          opacity={isOnboardingTutorialEnabled ? 0.1 : 1}
        >
          <SubNavButtons />
        </GridItem>

        {(contentType === 'tablist' || contentType === 'saved' || contentType === 'sessions') && (
          <GridItem
            ref={tablistScrollRef}
            id="tablist-scroll-container"
            className="scrollbars"
            pointerEvents="auto"
            colSpan={12}
            gridRow={2}
            overflow={shouldDisableTablistScroll ? 'hidden' : 'auto'}
            borderRadius="16px"
          >
            <Grid
              templateColumns="repeat(12, 1fr)"
              width="100%"
              flex="1 1 auto"
              minHeight="100%"
              maxWidth={'1600px'}
            >
              <GridItem
                ref={centralPanelRef}
                colSpan={12}
                minWidth={centralPanelMinWidth}
                maxWidth={isOnboardingTutorialEnabled ? '650px' : '1000px'}
                borderRadius="16px"
                backgroundColor={!isTablistEnabled ? undefined : '#f6f6f6'}
                overflow="hidden"
              >
                {isTablistEnabled && (
                  <SpotlightWrapper
                    anchorRef={centralPanelRef}
                    forceUpdate={currentStep}
                    isDisabled={isPutasideTablistEnabled && !isSavedTabsOnboardingStepActive}
                    shouldDisablePointerEvents={
                      isPutasideTablistEnabled && isSavedTabsOnboardingStepActive
                    }
                  >
                    {isMagicMomentTablistEnabled && <MagicMomentTablist />}
                    {isPinningTablistEnabled && <PinningTablist />}
                    {isOpenTablistEnabled && <OpenTablist />}
                    {isPutasideTablistEnabled &&
                      (isSimplifiedASTEnabled ? (
                        <PutasideTabListV2
                          isOnboardingTutorialEnabled={
                            isOnboardingTutorialEnabled && !isOnboardingConclusionStep
                          }
                          isSavedTabsOnboardingStepActive={isSavedTabsOnboardingStepActive}
                        />
                      ) : (
                        <PutasideTabList
                          defaultView={PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS}
                          isOnboardingTutorialEnabled={
                            isOnboardingTutorialEnabled && !isOnboardingConclusionStep
                          }
                          isSavedTabsOnboardingStepActive={isSavedTabsOnboardingStepActive}
                        />
                      ))}
                  </SpotlightWrapper>
                )}
              </GridItem>
            </Grid>
          </GridItem>
        )}

        {(contentType === 'actions' ||
          contentType === 'archive' ||
          contentType === 'project' ||
          contentType === 'settings') && (
          <GridItem
            colSpan={8}
            rowStart={2}
            rowSpan={2}
            height="100%"
            minWidth={centralPanelMinWidth}
            maxWidth="1000px"
            borderRadius="16px"
            backgroundColor="#f6f6f6"
            className="scrollbars"
            overflow="auto"
          >
            {contentType === 'settings' && <SettingsDashboard />}
            {contentType === 'actions' && <CompletedActionsDashboard />}
            {contentType === 'archive' && <ProjectArchiveDashboard authHeader={authHeader} />}
            {contentType === 'project' && !!projectId && (
              <ProjectDetailDashboard projectId={projectId} />
            )}
          </GridItem>
        )}
      </Grid>
    </Flex>
  )
}

export default MainDashboard
