import React, { FC, useRef } from 'react'
import { Flex, Box, Img, Link } from '@chakra-ui/react'
import ProjectsList from './ProjectsList'
import { useUserContext } from '../../../contexts/UserContext'
import { css, styled } from 'styled-components'
import { HiOutlineArchive } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../contexts/AuthContext'
import { MdHistory } from 'react-icons/md'
import { APP_LOGO_WORDMARK_URL, APP_NAME } from '../../../constants'
import { useReduxSelector } from '../../../redux/baseStore'
import ProjectCreationWidget from './ProjectCreationWidget'
import { selectIsFolderCreationModeActive } from '../../../redux/projectsSlice'
import SpotlightWrapper from '../onboarding/SpotlightWrapper'
import ProjectCreationOnboardingWidget from './ProjectCreationOnboardingWidget'

const NavLinkContainer = styled(Box)<{
  $isSelected: boolean
}>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 14px;
  cursor: pointer;
  box-sizing: border-box;
  gap: 6px;
  border: 1.5px solid transparent;
  transition:
    padding-left 0.2s,
    background-color 0.2s;

  h2 {
    transition: color 0.2s;
    color: #585858;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    cursor: pointer;
  }

  svg {
    transition: color 0.2s;
    color: #585858;
    width: 24px;
    height: 24px;
  }

  &:hover,
  &.force-hover {
    padding-left: 14px;

    ${({ $isSelected }) =>
      !$isSelected
        ? css`
            background-color: #ebebeb;
          `
        : ''};

    h2,
    svg {
      color: #000;
    }
  }

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          padding-left: 14px;
          border: 1.5px solid #585858;

          h2,
          svg {
            color: #000;
          }
        `
      : ''}
`

interface Props {
  detailProjectId?: string
  isOnboardingTutorialEnabled: boolean
  isProjectButtonOnboardingActive: boolean
  isFolderCreationOnboardingActive: boolean
  isProjectCreatedOnboardingActive: boolean
}

const ProjectsSidebar: FC<Props> = (props) => {
  const {
    detailProjectId,
    isOnboardingTutorialEnabled,
    isProjectButtonOnboardingActive,
    isFolderCreationOnboardingActive,
    isProjectCreatedOnboardingActive,
  } = props
  const { authHeader } = useAuthContext()
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)

  const projectCreationContainerRef = useRef<HTMLDivElement>(null)

  const isArchiveSelected = window.location.pathname === '/archive'
  const isCompletedActionsSelected = window.location.pathname === '/actions'

  const onClickArchive = () => {
    captureAnalytics('sidebar:archive_button_clicked')
    navigate('/archive')
  }

  const onClickCompletedActions = () => {
    captureAnalytics('sidebar:completed_actions_button_clicked')
    navigate('/actions')
  }

  const handleClickLogo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    captureAnalytics('sidebar:logo_clicked')
    navigate('/')
  }

  if (!authHeader) {
    return null
  }

  return (
    <Flex direction="column" justifyContent={'space-between'} h="100%" w="100%">
      <Box display={'flex'} alignItems={'center'} m="36px 24px 32px 24px">
        <Link href="/" onClick={handleClickLogo}>
          <Img src={APP_LOGO_WORDMARK_URL} alt={`${APP_NAME} logo`} height={'22px'} />
        </Link>
      </Box>
      {isFolderCreationModeActive && (
        <Box ref={projectCreationContainerRef} flex="1" width="100%" p="0 0 24px 24px" zIndex={10}>
          <SpotlightWrapper
            anchorRef={projectCreationContainerRef}
            isDisabled={!isFolderCreationOnboardingActive}
            xOffset={24}
            yOffset={0}
            widthOffset={-24}
            heightOffset={-24}
          >
            {isFolderCreationOnboardingActive ? (
              <ProjectCreationOnboardingWidget />
            ) : (
              <ProjectCreationWidget />
            )}
          </SpotlightWrapper>
        </Box>
      )}
      {!isFolderCreationModeActive && (
        <>
          <Box flex="1" overflow="hidden" width="100%" p="0 20px 0px 20px">
            <ProjectsList
              authHeader={authHeader}
              detailProjectId={detailProjectId}
              isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
              isProjectButtonOnboardingActive={isProjectButtonOnboardingActive}
              isProjectCreatedOnboardingActive={isProjectCreatedOnboardingActive}
            />
          </Box>
          <Box p="24px">
            <NavLinkContainer
              $isSelected={isCompletedActionsSelected}
              onClick={onClickCompletedActions}
            >
              <MdHistory />
              <h2>Completed actions</h2>
            </NavLinkContainer>
            <NavLinkContainer $isSelected={isArchiveSelected} onClick={onClickArchive}>
              <HiOutlineArchive fontSize="1.5em" />
              <h2>Archive</h2>
            </NavLinkContainer>
          </Box>
        </>
      )}
    </Flex>
  )
}

export default ProjectsSidebar
