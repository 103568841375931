import React, { FC, useEffect, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingTablistDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  const isGuidedAutoSaveSkipped =
    window.localStorage.getItem(WEBAPP_LOCAL_STORAGE_KEYS.OnboardingGuidedAutoSaveSkipped) ===
    'true'

  useEffect(() => {
    // Prevents bug where recently closed tabs are briefly included in the open count
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isLoading])

  const savedHeading = (
    <>
      {openTabPages && openTabPages.length === 0
        ? `Nice! Down to just the Skipper tab.`
        : `Nice! Down to just Skipper and ${openTabPages?.length ?? 'a few'} open ${
            (openTabPages?.length ?? 2) === 1 ? 'tab' : 'tabs'
          }.`}
      <br />
      <br />
      {`Every tab is saved for you - just visit Skipper's "All saved tabs" to find what you need.`}
    </>
  )

  const skippedHeading = (
    <>
      {openTabPages && openTabPages.length === 0
        ? `Okay! Down to just the Skipper tab. We'll suggest tabs to save and close once a day.`
        : `Okay! You have Skipper and ${openTabPages?.length ?? 'a few'} ${
            (openTabPages?.length ?? 2) === 1 ? 'tab' : 'tabs'
          } still open. We'll suggest tabs to save and close once a day.`}
      <br />
      <br />
      {`Every tab will be saved for you - just visit Skipper's "All saved tabs" to find what you need.`}
    </>
  )

  const heading = isGuidedAutoSaveSkipped ? skippedHeading : savedHeading

  const onButtonClick = () => {
    onNext()
  }

  return (
    <OnboardingStepTemplate
      isCardLoading={isLoading}
      phaseText={`1. DECLUTTERING`}
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={heading}
      buttonText={`Okay`}
      onButtonClick={onButtonClick}
    />
  )
}
export default OnboardingTablistDialog
