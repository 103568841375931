import React, { FC, useCallback, useState } from 'react'
import { Text, Flex, Box, Collapse } from '@chakra-ui/react'
import { styled } from 'styled-components'
import PutasideTabController from './PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { useGetAllSavedTabsQuery } from '../../../redux/services/skeema/tablist_pages.endpoints'
import {
  selectIsFolderCreationModeActive,
  selectPendingFolderCreationPages,
} from '../../../redux/projectsSlice'
import { useReduxSelector } from '../../../redux/baseStore'
import { FiChevronDown } from 'react-icons/fi'
import FavIconList, { FavIconListItemType } from '../FavIconList'
import { useUserContext } from '../../../contexts/UserContext'

const CollapseIcon = styled.span<{ $isCollapsed: boolean }>`
  height: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.2em;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  width: 16px;
  color: black;

  & > svg {
    display: flex;
    transform: ${({ $isCollapsed }) => `rotate(${$isCollapsed ? -90 : 0}deg)`};
    transition: transform 0.5s;
    transform-origin: 50% 50%;
  }
`

interface Props {
  isSmartSessionOnboardingActive: boolean
  isCollapsed: boolean
}

const MostUsedTabsSection: FC<Props> = (props) => {
  const { isSmartSessionOnboardingActive, isCollapsed } = props
  const { captureAnalytics } = useUserContext()
  const [isMostUsedTabsCollapsed, setIsMostUsedTabsCollapsed] = useState<boolean>(isCollapsed)

  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)
  const pendingFolderCreationPages = useReduxSelector(selectPendingFolderCreationPages)

  const { data: tablistPagesQueryResult } = useGetAllSavedTabsQuery({
    page: 1,
    should_include_most_visited: true,
  })
  const mastUsedPages = tablistPagesQueryResult?.most_visited_pages

  const handleClickMostUsedTabsSection = useCallback(() => {
    setIsMostUsedTabsCollapsed((prev) => {
      captureAnalytics('putaside_tab_list:most_used_tabs_section_collapse', {
        isCollapsed: !prev,
      })
      return !prev
    })
  }, [captureAnalytics])

  const handleClickExpandFavIcons = useCallback(() => {
    setIsMostUsedTabsCollapsed(false)
    captureAnalytics('putaside_tab_list:most_used_tabs_section_collapse', {
      isCollapsed: false,
      isFavIconsClicked: true,
    })
  }, [captureAnalytics])

  if (!mastUsedPages || mastUsedPages.length === 0) {
    return null
  }

  return (
    <Box mb="16px">
      <Flex alignItems="center" justifyContent="space-between" marginBottom="8px" marginLeft="12px">
        <Flex
          alignItems="center"
          cursor={!isSmartSessionOnboardingActive ? 'pointer' : 'default'}
          onClick={isSmartSessionOnboardingActive ? undefined : handleClickMostUsedTabsSection}
        >
          <Text as="h2" color="#000" fontSize="16px" fontWeight="600" lineHeight="24px">
            Most used tabs
          </Text>
          <CollapseIcon $isCollapsed={!!isMostUsedTabsCollapsed}>
            <FiChevronDown />
          </CollapseIcon>
        </Flex>
      </Flex>

      <Box
        flex="1 2 auto"
        display={isMostUsedTabsCollapsed ? 'flex' : 'none'}
        alignItems="center"
        justifyContent="flex-start"
        overflow="auto"
        minWidth="24px"
        maxWidth="100%"
        padding="0 6px"
      >
        <FavIconList
          items={mastUsedPages.map((t) => {
            return {
              id: t.id,
              url: t.url,
              faviconUrl: t.favicon_url,
              title: t.title,
              windowId: t.window_id,
              isOpen: true,
              tabId: parseInt(t.id),
            } as FavIconListItemType
          })}
          isClickable={true}
          areaName={TABLIST_AREA_NAME_ENUM.Open}
          size="md"
          showTitleTooltip={true}
          maxLength={8}
          onClickExpand={handleClickExpandFavIcons}
        />
      </Box>

      <Collapse in={!isMostUsedTabsCollapsed} animateOpacity>
        <Box>
          {mastUsedPages?.map((t, idx) => {
            return (
              <PutasideTabController
                key={t.id}
                id={t.id}
                page={t}
                showTimeString={false}
                queryValue={''}
                index={idx}
                numTotalResults={mastUsedPages.length}
                areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                isDraggingDisabled={isSmartSessionOnboardingActive}
                isHoverDisabled={isSmartSessionOnboardingActive}
                isTitleClickDisabled={isSmartSessionOnboardingActive}
                isSelectionDisabled={isFolderCreationModeActive}
                shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
                isPendingInFolderCreation={pendingFolderCreationPages.some((p) => p.id === t.id)}
              />
            )
          })}
        </Box>
      </Collapse>
    </Box>
  )
}

export default MostUsedTabsSection
