export interface ProjectPageType {
  id: string
  url: string
  title: string
  custom_title?: string
  order: number
  datetime_created: string
  datetime_updated: string
  last_access_datetime?: string
  instance_id?: number
  favicon_url?: string
}

export type ProjectPageCreationType = Pick<
  ProjectPageType,
  'url' | 'title' | 'favicon_url' | 'instance_id'
>

export interface ProjectType {
  id: string
  title: string
  order: number
  pages: ProjectPageType[]
  is_archived: boolean
  is_owner: boolean
  owner_email: string
  datetime_archived: string | null
  datetime_created: string
}

export type ProjectCreationType = Pick<ProjectType, 'title' | 'order'> & {
  pages: ProjectPageCreationType[]
  is_onboarding?: boolean
}

export enum PROJECT_PERMISSION_ENUM {
  NOT_SHARED = 'NOT_SHARED',
  VIEW = 'VIEW',
}

export interface ProjectPermissionsType {
  target: string
  permission: PROJECT_PERMISSION_ENUM
}
