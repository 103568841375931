import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFoundPage from '../../pages//notFound/NotFoundPage'
import LoginHandler from './login/LoginHandler'
import ExtensionInstalledPage from './extensionInstalled/ExtensionInstalledPage'
import InstallExtensionPage from './installExtension/InstallExtensionPage'
import UpdateExtensionPage from '../../pages/updateExtension/UpdateExtensionPage'
import MorningPageV3 from '../../pages/morningPageV3/MorningPage'
import MorningPageV4 from '../../pages/morningPageV4/MorningPage'
import MorningPageV5 from '../../pages/morningPageV5/MorningPage'
import ExtensionUninstalledPage from './extensionUninstalled/ExtensionUninstalledPage'
import AuthSyncPage from './authSync/AuthSyncPage'
import { CONTENT_TYPE as CONTENT_TYPE_V3 } from '../../pages/morningPageV3/MainDashboard'
import { CONTENT_TYPE as CONTENT_TYPE_V4 } from '../../pages/morningPageV4/MainDashboard'
import { CONTENT_TYPE as CONTENT_TYPE_V5 } from '../../pages/morningPageV5/MainDashboard'
import MobileSignupPage from './mobileSignup/MobileSignupPage'
import RequireDesktop from './RequireDesktop'
import RequireAuth from './RequireAuth'

enum ROUTES_ENUM {
  EMPTY = '/',
  AUTH_HANDLER = '/auth/handler',
  INSTALL_EXTENSION = '/extension/install',
  UPDATE_EXTENSION = '/extension/update',
  EXTENSION_INSTALLED = '/extension/installed',
  EXTENSION_UNINSTALLED = '/extension/uninstalled',
  SAVED = '/saved',
  COMPLETED_ACTIONS = '/actions',
  ARCHIVE = '/archive',
  PROJECTS = '/projects',
  FOLDERS = '/folders',
  SETTINGS = '/settings',
  AUTH_SYNC = '/auth-sync',
  SESSIONS = '/sessions',
  MOBILE_SIGNUP = '/mobile/signup',
}

const MorningPageSwitcher: FC<{
  pageType: 'v3' | 'v4' | 'v5'
  contentType: CONTENT_TYPE_V3 | CONTENT_TYPE_V4 | CONTENT_TYPE_V5
}> = (props) => {
  const { pageType, contentType } = props

  if (pageType === 'v3') {
    return <MorningPageV3 contentType={contentType} />
  }

  if (pageType === 'v4') {
    return <MorningPageV4 contentType={contentType} />
  }

  if (pageType === 'v5') {
    return <MorningPageV5 contentType={contentType} />
  }

  return <NotFoundPage />
}

interface Props {
  morningPageType: 'v3' | 'v4' | 'v5'
}

const RoutingPage: FC<Props> = (props) => {
  const { morningPageType } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Routes>
        <Route
          path={ROUTES_ENUM.AUTH_HANDLER}
          element={
            <RequireDesktop>
              <LoginHandler />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.INSTALL_EXTENSION}
          element={
            <RequireDesktop>
              <InstallExtensionPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.UPDATE_EXTENSION}
          element={
            <RequireDesktop>
              <UpdateExtensionPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.EXTENSION_INSTALLED}
          element={
            <RequireDesktop>
              <ExtensionInstalledPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.EXTENSION_UNINSTALLED}
          element={
            <RequireDesktop>
              <ExtensionUninstalledPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.EMPTY}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="tablist" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.COMPLETED_ACTIONS}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="actions" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.SAVED}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="saved" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.SESSIONS}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="sessions" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.ARCHIVE}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="archive" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={`${ROUTES_ENUM.FOLDERS}/:projectId`}
          element={<MorningPageSwitcher pageType={morningPageType} contentType="project" />}
        />
        <Route
          path={`${ROUTES_ENUM.PROJECTS}/:projectId`}
          element={<MorningPageSwitcher pageType={morningPageType} contentType="project" />}
        />
        <Route
          path={ROUTES_ENUM.SETTINGS}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="settings" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route path={ROUTES_ENUM.MOBILE_SIGNUP} element={<MobileSignupPage />} />
        <Route
          path={ROUTES_ENUM.AUTH_SYNC}
          element={
            <RequireDesktop>
              <AuthSyncPage />
            </RequireDesktop>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}

export default React.memo(RoutingPage)
